// @ts-nocheck
import { Column } from 'react-table'
import { DateCell } from './DateCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { NewsLatter } from '../../core/_models'
import { format } from 'date-fns'
import { ActionsCell } from './ActionsCell'

const newsLatterColumns: ReadonlyArray<Column<NewsLatter>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Joined Date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({ ...props }) => {
      const dateString: String = props.data[props.row.index].createdAt
      const createdDate = format(new Date(dateString), 'MMM dd, yyyy')
      return <DateCell date={createdDate} />
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ActionsCell id={props.data[props.row.index].id} />,
  },
]

export { newsLatterColumns }
