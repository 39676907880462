import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {BusinessCategory, BusinessCategoryQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const BUSINESS_CATEGORY_URL = `${API_URL}/businesses_categories`
const getBusinessCategories = (query: string): Promise<BusinessCategoryQueryResponse> => {
  return axios.get(`${BUSINESS_CATEGORY_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
 
    return data
  })
}

const getBusinessCategoryById = (id: ID): Promise<BusinessCategory | undefined> => {
  return (
    axios
      .get(`${BUSINESS_CATEGORY_URL}/${id}`) 
      .then(({data}: AxiosResponse) => { 
        return data.data
      })
  ) 
}

const createBusinessCategory = (offer: BusinessCategory): Promise<BusinessCategory | undefined> => {
  return axios
    .post(BUSINESS_CATEGORY_URL, offer)
    .then((response: AxiosResponse<Response<BusinessCategory>>) => response.data)
    .then((response: Response<BusinessCategory>) => response.data)
}

const updateBusinessCategory = (offer: BusinessCategory): Promise<BusinessCategory | undefined> => {
  return axios
    .put(`${BUSINESS_CATEGORY_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<BusinessCategory>>) => response.data)
    .then((response: Response<BusinessCategory>) => response.data)
}

const deleteBusinessCategory = (categoryId: ID): Promise<void> => {
  return axios.delete(`${BUSINESS_CATEGORY_URL}/${categoryId}`).then(() => {})
}

const deleteSelectedBusinessCategories = (categoryIds: Array<ID>): Promise<void> => {
  const requests = categoryIds.map((id) => axios.delete(`${BUSINESS_CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreBusinessCategory = (categoryId: ID): Promise<void> => {
  return axios.get(`${BUSINESS_CATEGORY_URL}/restore/${categoryId}`).then(() => {})
}

const restoreSelectedBusinessCategories = (categoryIds: Array<ID>): Promise<void> => {
  const requests = categoryIds.map((id) => axios.get(`${BUSINESS_CATEGORY_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  restoreBusinessCategory,
  restoreSelectedBusinessCategories,
  deleteBusinessCategory,
  deleteSelectedBusinessCategories,
  updateBusinessCategory,
  getBusinessCategoryById,
  createBusinessCategory,
  getBusinessCategories,
}
