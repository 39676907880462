import {FC, useState} from 'react'
import {DashBoardData} from './DashBoardData'
import {useQuery} from 'react-query'
import {QUERIES, stringifyRequestQuery} from '../../../_metronic/helpers'
import {useQueryRequest} from '../../modules/management/core/QueryRequestProvider'
import {getAllDataById} from '../../modules/management/core/_requests'
import {MdOutlineFestival, MdOutlineHome} from 'react-icons/md'
import {TbRecharging} from 'react-icons/tb'
import {BsInfoLg} from 'react-icons/bs'
import {FaAddressCard, FaUserPlus} from 'react-icons/fa'
import {BiBarChartAlt, BiSolidOffer} from 'react-icons/bi'
import {IoMdAddCircleOutline} from 'react-icons/io'
import {MdOutlineRealEstateAgent} from 'react-icons/md'
import {Loading} from '../../modules/offers types/components/loading/Loading'
import {FaMoneyBillTrendUp} from 'react-icons/fa6'
const DashboardWrapper: FC = () => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(stringifyRequestQuery(state))
  const {
    isFetching,
    isLoading,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.GEN_QNA_URL}-${query}`,
    () => {
      return getAllDataById(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const realEstateCount = response?.realEstateCount
  const jobCount = response?.jobCount
  const infoCount = response?.infoCount
  const offerCount = response?.offerCount
  const planCount = response?.planCount
  const userCount = response?.userCount
  const businessCount = response?.businessCount
  const businessCountCurrentMonth = response?.businessCountCurrentMonth

  const informationCountCurrentMonth = response?.informationCountCurrentMonth
  const jobCountCurrentMonth = response?.jobCountCurrentMonth
  const offerCountCurrentMonth = response?.offerCountCurrentMonth
  const realEstateCountCurrentMonth = response?.realEstateCountCurrentMonth

  const totalPayment = response?.totalPayment
  const userCountCurrentMonth = response?.userCountCurrentMonth
  if (isLoading) return <Loading />
  if (!response) {
    return <div>No data available</div>
  }
  return (
    <>
      <div className='row mb-4'>
        <div className='col-3 mb-5'>
          <DashBoardData
            title='User '
            icon={<FaUserPlus className='adcd_icon' />}
            value={userCount}
            monthValue={userCountCurrentMonth}
            description='This Month'
          />
        </div>

        <div className='col-3 mb-5'>
          <DashBoardData
            value={infoCount}
            monthValue={informationCountCurrentMonth}
            title='Information'
            icon={<BsInfoLg className='  adcd_icon' />}
            description='This Month'
          />
        </div>
        <div className='col-3 mb-5'>
          <DashBoardData
            title='Total Offers'
            icon={<BiSolidOffer className='adcd_icon' />}
            value={offerCount}
            monthValue={offerCountCurrentMonth}
            description='This Month'
          />
        </div>
        <div className='col-3 mb-5'>
          <DashBoardData
            title='Total Jobs'
            icon={<FaAddressCard className='adcd_icon' />}
            value={jobCount}
            monthValue={jobCountCurrentMonth}
            description='This Month'
          />
        </div>

        <div className='col-3 mb-5'>
          <DashBoardData
            title='Total Business'
            icon={<BiBarChartAlt className='adcd_icon' />}
            value={businessCount}
            monthValue={businessCountCurrentMonth}
            description='This Month'
          />
        </div>
        <div className='col-3 mb-5'>
          <DashBoardData
            title='Real Estate'
            icon={<MdOutlineFestival className='adcd_icon' />}
            value={realEstateCount}
            monthValue={realEstateCountCurrentMonth}
            description='This Month'
          />
        </div>
        <div className='col-3 mb-5'>
          <DashBoardData
            title='Total Sales'
            icon={<FaMoneyBillTrendUp className='adcd_icon' />}
            value={planCount}
            monthValue={totalPayment}
            description='This Month'
          />
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
