/* eslint-disable jsx-a11y/anchor-is-valid */
// import React from 'react'
// import {Card3} from '../../../../_metronic/partials/content/cards/Card3'

export function Connections() {
  return (
    <>
       <h3 className='fw-bolder my-2'>
         Connections
        </h3>
    </>
  )
}
