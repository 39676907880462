/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ID} from '../../../../../_metronic/helpers'
type Props = {
  user: {
    name?: string
    last_name?: string
    first_name?: string
    email?: string
    id: ID
  }
}

const InfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center pointer'>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>
          {user?.name ? user?.name : `${user?.first_name} ${user?.last_name}`}
        </a>

        {user?.email ? <span className='text-muted fw-bold fs-6'>{user?.email}</span> : null}
      </div>
    </div>
  )
}

export {InfoCell}
