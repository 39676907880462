import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {BlogCategory, BlogCategoryQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const BLOG_CATEGORY_URL = `${API_URL}/blog_categories`
const getBlogCategories = (query: string): Promise<BlogCategoryQueryResponse> => {
  return axios.get(`${BLOG_CATEGORY_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getBlogCategoryById = (id: ID): Promise<BlogCategory | undefined> => {
  return axios.get(`${BLOG_CATEGORY_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...user} = data.data
    const modifiedUser: BlogCategory = {id: _id, ...user}
    return modifiedUser
  })
}

const createBlogCategory = (offer: BlogCategory): Promise<BlogCategory | undefined> => {
  return axios
    .post(BLOG_CATEGORY_URL, offer)
    .then((response: AxiosResponse<Response<BlogCategory>>) => response.data)
    .then((response: Response<BlogCategory>) => response.data)
}

const updateBlogCategory = (offer: BlogCategory): Promise<BlogCategory | undefined> => {
  return axios
    .put(`${BLOG_CATEGORY_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<BlogCategory>>) => response.data)
    .then((response: Response<BlogCategory>) => response.data)
}

const deleteBlogCategory = (offerId: ID): Promise<void> => {
  return axios.delete(`${BLOG_CATEGORY_URL}/${offerId}`).then(() => {})
}

const deleteSelectedOffersTypes = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.delete(`${BLOG_CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreSelectedBlogCategories = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.get(`${BLOG_CATEGORY_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreBlogCategory = (offerId: ID): Promise<void> => {
  return axios.get(`${BLOG_CATEGORY_URL}/restore/${offerId}`).then(() => {})
}

export {
  deleteBlogCategory,
  restoreSelectedBlogCategories,
  restoreBlogCategory,
  deleteSelectedOffersTypes,
  updateBlogCategory,
  getBlogCategoryById,
  createBlogCategory,
  getBlogCategories,
}
