import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {Plan, initialPlan} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createPlan, updatePlan} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Loading} from '../components/loading/Loading'
import _ from 'lodash'
import {isEmpty} from 'lodash'
type Props = {
  isLoading: boolean
  plan: Plan
}

const editPlan = Yup.object().shape({
  // name: Yup.string().required('Name is required'),
  // jobPosts: Yup.string().required('job Posts is required'),
  // propertyPosts: Yup.string().required('Property Posts is required'),
  // businesses: Yup.string().required('Business is required'),
  // price: Yup.string().required('price is required'),
  // images: Yup.string().required('images is required'),
  // videos: Yup.string().required('videos is required'),
})

const PlanEditModalForm: FC<Props> = ({plan, isLoading}) => {
  const [isBusinessUnlimited, setIsBusinessUnlimited] = useState<boolean>(false)
  const [isJobUnlimited, setIsJobUnlimited] = useState<boolean>(false)
  const [isPropertyUnlimited, setIsPropertyUnlimited] = useState<boolean>(false)

  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  useEffect(() => {
    if (!_.isEmpty(plan)) {
      if (plan.businesses === 'unlimited') {
        setIsBusinessUnlimited(true)
      }
      if (plan.jobPosts === 'unlimited') {
        setIsJobUnlimited(true)
      }
      if (plan.propertyPosts === 'unlimited') {
        setIsPropertyUnlimited(true)
      }
    }
  }, [plan])
  const [planForEdit] = useState<Plan>({
    ...initialPlan,
    ...plan,
  })
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: planForEdit,
    validationSchema: editPlan,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const updatedPlan = {
        ...values,
        businesses: isBusinessUnlimited ? 'unlimited' : values.businesses,
        propertyPosts: isPropertyUnlimited ? 'unlimited' : values.propertyPosts,
        jobPosts: isJobUnlimited ? 'unlimited' : values.jobPosts,
      }
      try {
        if (isNotEmpty(updatedPlan.id)) {
          await updatePlan(updatedPlan)
          cancel(true)
        } else {
          await createPlan(updatedPlan)
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row'>
            <div className='col-lg-6'>
              <div className='fv-row mb-4 '>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Name'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </div>
            <div className='col-lg-6 '>
              {/* begin::Input group */}
              <div className='fv-row mb-4 '>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Price</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Price'
                  {...formik.getFieldProps('price')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.price && formik.errors.price},
                    {
                      'is-valid': formik.touched.price && !formik.errors.price,
                    }
                  )}
                  type='price'
                  name='price'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {/* end::Input */}
                {formik.touched.price && formik.errors.price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.price}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-lg-12 pb-4 '>
              <div className='fv-row '>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Businesses </label>
                {/* begin::Input group */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='d-flex fv-row align-items-center '>
                      <div className='form-check form-check-custom form-check-solid me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          data-kt-check={isBusinessUnlimited}
                          data-kt-check-target='#kt_table_users .form-check-input'
                          checked={isBusinessUnlimited}
                          onChange={() => setIsBusinessUnlimited(!isBusinessUnlimited)}
                        />
                      </div>
                      <label className='fw-bold fs-6 '>Unlimited</label>
                    </div>
                  </div>
                  <div className='col-lg-6 '>
                    {/* begin::Input group */}
                    {!isBusinessUnlimited ? (
                      <div className='d-flex fv-row align-items-center'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 me-3'>Limit</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          placeholder='Businesses'
                          {...formik.getFieldProps('businesses')}
                          className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.businesses && formik.errors.businesses},
                            {
                              'is-valid': formik.touched.businesses && !formik.errors.businesses,
                            }
                          )}
                          type='text'
                          name='businesses'
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.businesses && formik.errors.businesses && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.businesses}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {/* end::Input group */}
                  </div>
                </div>
                {/* end::Input group */}
              </div>
            </div>
            <div className='col-lg-12 pb-4 '>
              <div className='fv-row mb-4 '>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Jobs </label>
                {/* begin::Input group */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='d-flex fv-row align-items-center '>
                      <div className='form-check form-check-custom form-check-solid me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          data-kt-check={isJobUnlimited}
                          data-kt-check-target='#kt_table_users .form-check-input'
                          checked={isJobUnlimited}
                          onChange={() => setIsJobUnlimited(!isJobUnlimited)}
                        />
                      </div>
                      <label className='fw-bold fs-6 '>Unlimited</label>
                    </div>
                  </div>
                  <div className='col-lg-6 '>
                    {/* begin::Input group */}
                    {!isJobUnlimited ? (
                      <div className='d-flex fv-row align-items-center'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 me-3'>Limit</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          placeholder='Job Post'
                          {...formik.getFieldProps('jobPosts')}
                          className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.jobPosts && formik.errors.jobPosts},
                            {
                              'is-valid': formik.touched.jobPosts && !formik.errors.jobPosts,
                            }
                          )}
                          type='text'
                          name='jobPosts'
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.jobPosts && formik.errors.jobPosts && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.jobPosts}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {/* end::Input group */}
                  </div>
                </div>
                {/* end::Input group */}
              </div>
            </div>
            <div className='col-lg-12 pb-4 '>
              <div className='fv-row '>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Real Estates </label>
                {/* begin::Input group */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='d-flex fv-row align-items-center '>
                      <div className='form-check form-check-custom form-check-solid me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          data-kt-check={isPropertyUnlimited}
                          data-kt-check-target='#kt_table_users .form-check-input'
                          checked={isPropertyUnlimited}
                          onChange={() => setIsPropertyUnlimited(!isPropertyUnlimited)}
                        />
                      </div>
                      <label className='fw-bold fs-6 '>Unlimited</label>
                    </div>
                  </div>
                  <div className='col-lg-6 '>
                    {/* begin::Input group */}
                    {!isPropertyUnlimited ? (
                      <div className='d-flex fv-row align-items-center'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 me-3'>Limit</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          placeholder='Real Estate Post'
                          {...formik.getFieldProps('propertyPosts')}
                          className={clsx(
                            'form-control form-control-solid mb-lg-0',
                            {
                              'is-invalid':
                                formik.touched.propertyPosts && formik.errors.propertyPosts,
                            },
                            {
                              'is-valid':
                                formik.touched.propertyPosts && !formik.errors.propertyPosts,
                            }
                          )}
                          type='text'
                          name='propertyPosts'
                          autoComplete='off'
                          disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.propertyPosts && formik.errors.propertyPosts && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.propertyPosts}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {/* end::Input group */}
                  </div>
                </div>
                {/* end::Input group */}
              </div>
            </div>
            <div className='col-lg-6  '>
              {/* begin::Input group */}
              <div className='fv-row  mb-4'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Images Max Limit</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Images'
                  {...formik.getFieldProps('images')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.images && formik.errors.images},
                    {
                      'is-valid': formik.touched.images && !formik.errors.images,
                    }
                  )}
                  type='text'
                  name='images'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {/* end::Input */}
                {formik.touched.images && formik.errors.images && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.images}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-lg-6 '>
              {/* begin::Input group */}
              <div className='fv-row mb-4 '>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Videos Max Limit</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Videos'
                  {...formik.getFieldProps('videos')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.videos && formik.errors.videos},
                    {
                      'is-valid': formik.touched.videos && !formik.errors.videos,
                    }
                  )}
                  type='text'
                  name='videos'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {/* end::Input */}
                {formik.touched.videos && formik.errors.videos && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.videos}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-lg-12 pb-4 '>
              <div className='fv-row mb-4 '>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Offers per business </label>
                {/* begin::Input group */}
                <div className='row'>
                  <div className='col-lg-4 '>
                    <div className='fv-row'>
                      {/* begin::Label */}
                      <label className='required fw-bold fs-6 me-3'>Limit</label>

                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        placeholder='Offer Post'
                        {...formik.getFieldProps('offers')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.offers && formik.errors.offers},
                          {
                            'is-valid': formik.touched.offers && !formik.errors.offers,
                          }
                        )}
                        type='text'
                        name='offers'
                        autoComplete='off'
                        disabled={formik.isSubmitting || isLoading}
                      />
                      {/* end::Input */}
                      {formik.touched.offers && formik.errors.offers && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.offers}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* end::Input group */}
                  </div>
                  <div className='col-lg-4  '>
                    {/* begin::Input group */}
                    <div className='fv-row  mb-4'>
                      {/* begin::Label */}
                      <label className='required fw-bold fs-6 mb-2'>
                        Max Images Limit per offer
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        placeholder='Images'
                        {...formik.getFieldProps('OfferImagesLimit')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.OfferImagesLimit && formik.errors.OfferImagesLimit,
                          },
                          {
                            'is-valid':
                              formik.touched.OfferImagesLimit && !formik.errors.OfferImagesLimit,
                          }
                        )}
                        type='text'
                        name='OfferImagesLimit'
                        autoComplete='off'
                        disabled={formik.isSubmitting || isLoading}
                      />
                      {/* end::Input */}
                      {formik.touched.images && formik.errors.images && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.images}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Input group */}
                  </div>
                  <div className='col-lg-4 '>
                    {/* begin::Input group */}
                    <div className='fv-row mb-4 '>
                      {/* begin::Label */}
                      <label className='required fw-bold fs-6 mb-2'>
                        Max Videos Limit per offer
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        placeholder='Videos'
                        {...formik.getFieldProps('OfferVideosLimit')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.OfferVideosLimit && formik.errors.OfferVideosLimit,
                          },
                          {
                            'is-valid':
                              formik.touched.OfferVideosLimit && !formik.errors.OfferVideosLimit,
                          }
                        )}
                        type='text'
                        name='OfferVideosLimit'
                        autoComplete='off'
                        disabled={formik.isSubmitting || isLoading}
                      />
                      {/* end::Input */}
                      {formik.touched.videos && formik.errors.videos && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.videos}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Input group */}
                  </div>
                </div>
                {/* end::Input group */}
              </div>
            </div>
          </div>

          {/* begin::Input group */}

          <div className='mb-7 d-flex fv-row align-items-center '>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 me-10'>Status</label>
            {/* end::Label */}
            {/* begin::Roles */}
            <div className='d-flex fv-row align-items-center justify-content-around '>
              {/* begin::Input row */}
              <div className='d-flex fv-row me-10'>
                {/* begin::Radio */}
                <div className='form-check form-check-custom form-check-solid'>
                  {/* begin::Input */}
                  <input
                    className='form-check-input me-3'
                    {...formik.getFieldProps('status')}
                    name='status'
                    type='radio'
                    value='Active'
                    id='kt_modal_update_role_option_0'
                    checked={formik.values.status === 'Active'}
                    disabled={formik.isSubmitting || isLoading}
                  />

                  {/* end::Input */}
                  {/* begin::Label */}
                  <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                    <div className='fw-bolder text-gray-800'>Active</div>
                  </label>
                  {/* end::Label */}
                </div>
                {/* end::Radio */}
              </div>
              {/* end::Input row */}
              {/* begin::Input row */}
              <div className='d-flex fv-row me-10'>
                {/* begin::Radio */}
                <div className='form-check form-check-custom form-check-solid'>
                  {/* begin::Input */}
                  <input
                    className='form-check-input me-3'
                    {...formik.getFieldProps('status')}
                    name='status'
                    type='radio'
                    value='Upcoming'
                    id='kt_modal_update_role_option_0'
                    checked={formik.values.status === 'Upcoming'}
                    disabled={formik.isSubmitting || isLoading}
                  />

                  {/* end::Input */}
                  {/* begin::Label */}
                  <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                    <div className='fw-bolder text-gray-800'>Upcoming</div>
                  </label>
                  {/* end::Label */}
                </div>
                {/* end::Radio */}
              </div>
              <div className='d-flex fv-row'>
                {/* begin::Radio */}
                <div className='form-check form-check-custom form-check-solid'>
                  {/* begin::Input */}
                  <input
                    className='form-check-input me-3'
                    {...formik.getFieldProps('status')}
                    name='status'
                    type='radio'
                    value='Inactive'
                    id='kt_modal_update_role_option_0'
                    checked={formik.values.status === 'Inactive'}
                    disabled={formik.isSubmitting || isLoading}
                  />

                  {/* end::Input */}
                  {/* begin::Label */}
                  <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                    <div className='fw-bolder text-gray-800'>Inactive</div>
                  </label>
                  {/* end::Label */}
                </div>
                {/* end::Radio */}
              </div>
              {/* end::Input row */}
            </div>
            {/* end::Roles */}
          </div>

          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {PlanEditModalForm}
