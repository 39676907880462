import React from 'react'
import {SocialMedia} from './SocialMedia'
const ContactInfo = ({contactInfo, socialMedia = true}) => {
  return (
    <>
      <div className='card card-xxl-stretch mb-5 mb-xl-10'>
        <div className='card-header border-1'>
          <h3 className='card-title fw-bold text-dark  mb-2 text-dark'>Contact Info</h3>
        </div>
        <div className='card-body pt-3'>
          <div className='d-flex align-items-center mb-7'>
            <div className='d-flex flex-column'>
              <a
                href={contactInfo?.website}
                target='_blank'
                rel='noopener noreferrer'
                className='text-dark text-hover-primary fs-6 fw-bold'
              >
                Website Name
              </a>
              <a href={contactInfo?.website}>
                <span className='text-muted fw-semibold'>
                  {contactInfo?.website ? contactInfo?.website : 'Not Available'}
                </span>
              </a>
            </div>
          </div>
          <div className='d-flex align-items-center mb-7'>
            <div className='d-flex flex-column'>
              <a
                href={`https://maps.google.com?q=${contactInfo?.address}, ${contactInfo?.city}, ${contactInfo?.state}, ${contactInfo?.zipCode}`}
                target='_blank'
                rel='noopener noreferrer'
                className='text-dark text-hover-primary fs-6 fw-bold'
              >
                Location
              </a>
              <a
                href={`https://maps.google.com?q=${contactInfo?.address}, ${contactInfo?.city}, ${contactInfo?.state}, ${contactInfo?.zipCode}`}
                target='_blank'
              >
                <span className='text-muted fw-semibold'>
                  {contactInfo?.address}, {contactInfo?.city}, {contactInfo?.state},{' '}
                  {contactInfo?.zipCode}
                </span>
              </a>
            </div>
          </div>

          <div className='d-flex align-items-center mb-7'>
            {/* begin::Text */}
            <div className='d-flex flex-column'>
              <a
                href={contactInfo?.phone ? `tel:${contactInfo?.phone}` : '#'}
                className='text-dark text-hover-primary fs-6 fw-bold'
              >
                Contact No
              </a>
              <a
                href={contactInfo?.phone ? `tel:${contactInfo?.phone}` : '#'}
                className='text-dark text-hover-primary fs-6 fw-bold'
              >
                <span className='text-muted fw-semibold'>
                  {contactInfo?.phone ? contactInfo?.phone : 'Not Available'}
                </span>
              </a>
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-7 '>
            {/* begin::Text */}
            {
              <div className='d-flex flex-column'>
                <a
                  href={`mailto:${contactInfo?.email}`}
                  className='text-dark text-hover-primary fs-6 fw-bold'
                >
                  Email
                </a>
                <a href={`mailto:${contactInfo.email}`}>
                  <span className='text-muted fw-semibold'>
                    {contactInfo?.email ? contactInfo?.email : 'Not Available'}
                  </span>
                </a>
              </div>
            }

            {/* end::Text */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          {contactInfo.instagram || contactInfo.youtube || contactInfo.facebook ? (
            <SocialMedia socialInfo={contactInfo} />
          ) : null}
        </div>
      </div>
    </>
  )
}

export {ContactInfo}
