import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedPostCategories, restoreSelectedPostCategories} from '../../core/_requests'
import {isTrashed} from '../../../../../utils/helperFn'

const ListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedPostCategories(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.POST_CATEGORY_LIST}-${query}`])
      clearSelected()
    },
  })
  const restoreSelectedItems = useMutation(() => restoreSelectedPostCategories(selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.POST_CATEGORY_LIST}-${query}`])
      clearSelected()
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>
      {isTrashed(query) ? (
        <div className='me-2'>
          <button
            type='button'
            className='btn btn-success'
            onClick={async () => await restoreSelectedItems.mutateAsync()}
          >
            Restore Selected
          </button>
        </div>
      ) : null}
      <div>
        <button
          type='button'
          className='btn btn-danger'
          onClick={async () => await deleteSelectedItems.mutateAsync()}
        >
          Delete Selected
        </button>
      </div>
    </div>
  )
}

export {ListGrouping}
