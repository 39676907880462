import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {InfoDepartment, InfoDepartmentQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const InfoDepartment_URL = `${API_URL}/information`

const getAllInfoDepartment = (
  query: string,
  categoryId: string
): Promise<InfoDepartmentQueryResponse> => {
  return axios
    .get(`${InfoDepartment_URL}/admin?category=${categoryId}&${query}`)
    .then(({data}: AxiosResponse) => {
      return data
    })
}
const importInfoDepartmentes = (bulkData): Promise<InfoDepartment | undefined> => {
  return axios
    .post(InfoDepartment_URL, bulkData)
    .then((response: AxiosResponse<Response<InfoDepartment>>) => response.data)
    .then((response: Response<InfoDepartment>) => response.data)
}
const restoreSelectedInformation = (businessIds: Array<ID>): Promise<void> => {
  const requests = businessIds.map((id) => axios.get(`${InfoDepartment_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}
const getInfoDepartmentById = (id: ID): Promise<InfoDepartment | undefined> => {
  return axios.get(`${InfoDepartment_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...business} = data.data
    const modifiedUser: InfoDepartment = {id: _id, ...business}
    return modifiedUser
  })
}

const createInfoDepartment = (business: InfoDepartment): Promise<InfoDepartment | undefined> => {
  return axios
    .post(InfoDepartment_URL, business)
    .then((response: AxiosResponse<Response<InfoDepartment>>) => response.data)
    .then((response: Response<InfoDepartment>) => response.data)
}

const updateInfoDepartment = (business: InfoDepartment): Promise<InfoDepartment | undefined> => {
  return axios
    .put(`${InfoDepartment_URL}/${business.id}`, business)
    .then((response: AxiosResponse<Response<InfoDepartment>>) => response.data)
    .then((response: Response<InfoDepartment>) => response.data)
}

const deleteInfoDepartment = (businessId: ID): Promise<void> => {
  return axios.delete(`${InfoDepartment_URL}/${businessId}`).then(() => {})
}
const restoreInformatonCategory = (businessId: ID): Promise<void> => {
  return axios.get(`${InfoDepartment_URL}/restore/${businessId}`).then(() => {})
}
const deleteSelectedInfoDepartment = (businessIds: Array<ID>): Promise<void> => {
  const requests = businessIds.map((id) => axios.delete(`${InfoDepartment_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const removeInfoImage = (realEstateId: ID, data: any): Promise<void> => {
  return axios
    .post(`${InfoDepartment_URL}/${realEstateId}/removeImage`, data)
    .then((response) => response.data)
}
// upload bulk realEstate
const importInformation = (bulkData): Promise<InfoDepartment | undefined> => {
  return axios
    .post(`${InfoDepartment_URL}/admin`, bulkData)
    .then((response: AxiosResponse<Response<InfoDepartment>>) => response.data)
    .then((response: Response<InfoDepartment>) => response.data)
}
export {
  deleteInfoDepartment,
  restoreInformatonCategory,
  removeInfoImage,
  importInfoDepartmentes,
  importInformation,
  deleteSelectedInfoDepartment,
  getInfoDepartmentById,
  createInfoDepartment,
  restoreSelectedInformation,
  updateInfoDepartment,
  getAllInfoDepartment,
}
