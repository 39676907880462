// import {ID, Response} from '../../../../_metronic/helpers'
import {ID, Response} from '../../../../_metronic/helpers'
export type PostCategory = {
  id?: ID
  name?: ""
  description?: string
  isCommentEnable?: boolean
  slug?: string
  createdAt?: string
  deletedAt?: string
}

export type PostCategoryQueryResponse = Response<Array<PostCategory>>

export const initialCategory: PostCategory = {
  name: '',
  description: '',
  isCommentEnable: false
}
