import {useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {removeExportedEnquiryFile} from '../core/_requests'
import {useQueryClient, useMutation} from 'react-query'
import {format} from 'date-fns'
function FileDownload({fileURL, fileName, closeFileModal}) {
  const queryClient = useQueryClient()

  const downloadFile = async () => {
    try {
      const response = await fetch(fileURL)
      const blob = await response.blob()

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([blob]))
      link.download = fileName

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      closeFileModal(false)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }
  const getCreatedDate = (data) => {
    const match = data.match(/(\d+)/)

    const timestamp = match ? match[1] : null

    const timestampVal = parseInt(timestamp, 10)
    const date = new Date(timestampVal)

    const formattedDate = format(date, 'dd-mm-yyyy')
    return formattedDate
  }
  return (
    <>
      <div className='' data-kt-user-table-toolbar='base'>
        {fileName ? (
          <div className='mb-5 d-flex align-items-center justify-content-between'>
            <h5 className='fw-bolder'>{fileName}</h5>
            <h5 className='fw-bolder'>{getCreatedDate(fileName)}</h5>
          </div>
        ) : (
          <div className='d-flex align-items-center justify-content-center'>
            <p className='text-muted'>No file Available right now !</p>
          </div>
        )}
        <div className='d-flex justify-content-center'>
          <button onClick={downloadFile} type='button' className='btn btn-light-primary me-3'>
            <KTIcon iconName='exit-down' className='fs-2' />
            Download Now
          </button>
        </div>
      </div>
    </>
  )
}

export {FileDownload}
