import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {InformationCategory, InformationCategoryQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const INFORMATION_CATEGORY_URL = `${API_URL}/information_category`
const getInformationCategories = (query: string): Promise<InformationCategoryQueryResponse> => {
  return axios.get(`${INFORMATION_CATEGORY_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getInformationCategoryById = (id: ID): Promise<InformationCategory | undefined> => {
  return axios.get(`${INFORMATION_CATEGORY_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...informationCategory} = data.data
    const modifiedUser: InformationCategory = {id: _id, ...informationCategory}
    return modifiedUser
  })
}

const createInformationCategory = (
  offer: InformationCategory
): Promise<InformationCategory | undefined> => {
  return axios
    .post(INFORMATION_CATEGORY_URL, offer)
    .then((response: AxiosResponse<Response<InformationCategory>>) => response.data)
    .then((response: Response<InformationCategory>) => response.data)
}

const updateInformationCategory = (
  offer: InformationCategory
): Promise<InformationCategory | undefined> => {
  return axios
    .put(`${INFORMATION_CATEGORY_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<InformationCategory>>) => response.data)
    .then((response: Response<InformationCategory>) => response.data)
}

const deleteInformationCategory = (categoryId: ID): Promise<void> => {
  return axios.delete(`${INFORMATION_CATEGORY_URL}/${categoryId}`).then(() => {})
}

const deleteSelectedInformationCategories = (categoryIds: Array<ID>): Promise<void> => {
  const requests = categoryIds.map((id) => axios.delete(`${INFORMATION_CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreInformationListCategory = (categoryIds: ID): Promise<void> => {
  return axios.get(`${INFORMATION_CATEGORY_URL}/restore/${categoryIds}`).then(() => {})
}
const restoreSelectedInformationList = (categoryIds: Array<ID>): Promise<void> => {
  const requests = categoryIds.map((id) => axios.get(`${INFORMATION_CATEGORY_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteInformationCategory,
  restoreSelectedInformationList,
  restoreInformationListCategory,
  deleteSelectedInformationCategories,
  updateInformationCategory,
  getInformationCategoryById,
  createInformationCategory,
  getInformationCategories,
}
