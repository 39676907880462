/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {InformationCategoryListWrapper} from '../../modules/information-list/InformationCategoryList'

const InformationCategoryPage: FC = () => {
  const informationBreadcrum: Array<PageLink> = [
    {
      title: 'Information Categories',
      path: '/information-categories',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={informationBreadcrum}>Information list</PageTitle>
      <InformationCategoryListWrapper />
    </>
  )
}

export {InformationCategoryPage}
