import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  status?: string
}

const StatusCell: FC<Props> = ({status}) => (
  <>
    {status && (
      <div
        className={`badge fw-bolder ${
          status === 'Inactive' ? 'badge-light-danger' : 'badge-light-success'
        }`}
      >
        {status}
      </div>
    )}
  </>
)
export {StatusCell}
