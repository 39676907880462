import {useMemo} from 'react'
import _ from 'lodash'
import ReactPlayer from 'react-player'

const VideosSection = ({videos}) => {
  const videoArray = useMemo(() => {
    return videos.filter((element) => element !== '' && element !== ' ')
  }, [videos])

  if (!_.isArray(videoArray) || !videoArray.length) return null

  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>Videos</h3>
      </div>
      <div className='card-body pt-3'>
        {videos?.map((item, index) => (
          <div key={index} className='mb-5'>
            {/* begin::Video */}
            <div className='mb-5'>
              <ReactPlayer
                url={item}
                width='100%'
                height='300px'
                controls={true}
                className='react-player'
              />
            </div>
            {/* end::Video */}
          </div>
        ))}
      </div>
    </div>
  )
}

export {VideosSection}
