import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Service, ServiceQueryResponse} from './_models'
const API_URL = process.env.REACT_APP_API_URL
const Service_URL = `${API_URL}/professional_services`
const getServices = (query: string): Promise<ServiceQueryResponse> => {
  return axios.get(`${Service_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getServiceById = (id: ID): Promise<Service | undefined> => {
  return axios.get(`${Service_URL}/${id}`).then(({data}: AxiosResponse) => {
    return data.data
  })
}

const createService = (service: Service): Promise<Service | undefined> => {
  return axios
    .post(Service_URL, service)
    .then((response: AxiosResponse<Response<Service>>) => response.data)
    .then((response: Response<Service>) => response.data)
}

const updateService = (service: Service): Promise<Service | undefined> => {
  return axios
    .put(`${Service_URL}/${service.id}`, service)
    .then((response: AxiosResponse<Response<Service>>) => response.data)
    .then((response: Response<Service>) => response.data)
}

const deleteService = (serviceId: ID): Promise<void> => {
  return axios.delete(`${Service_URL}/${serviceId}`).then(() => {})
}

const deleteSelectedService = (serviceIds: Array<ID>): Promise<void> => {
  const requests = serviceIds.map((id) => axios.delete(`${Service_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreSelectedProfessional = (serviceIds: Array<ID>): Promise<void> => {
  const requests = serviceIds.map((id) => axios.get(`${Service_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreProServices = (serviceIds: ID): Promise<void> => {
  return axios.get(`${Service_URL}/restore/${serviceIds}`).then(() => {})
}

export {
  deleteService,
  restoreProServices,
  restoreSelectedProfessional,
  deleteSelectedService,
  updateService,
  getServiceById,
  createService,
  getServices,
}
