import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
const BusinessInfo = ({businessInfo}) => {
  if (!businessInfo) {
    return null
  }
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>BusinessDetails</h3>
      </div>
      <div className='card-body pt-3'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-95px symbol-fixed position-relative'>
              <img
                src={businessInfo?.avatar ? businessInfo?.avatar : blankImg}
                alt='Metornic'
                className='w-125px h-125px'
              />
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {businessInfo?.name}
                  </a>
                  {businessInfo.approvalStatus === 'Verified' ? (
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  ) : null}
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='person' className='fs-4 me-1' />
                    {businessInfo?.phone}
                  </a>
                  {businessInfo?.website ? (
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='globe' className='fs-4 me-1' />
                      {businessInfo?.website}
                    </a>
                  ) : null}

                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {businessInfo?.email}
                  </a>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='person' className='fs-4 me-1' />
                    {businessInfo?.address}, {businessInfo?.city}, {businessInfo?.state},
                    {businessInfo?.zipCode}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {BusinessInfo}
