import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ServiceListWrapper} from './ServiceList'
import {ServiceDetail} from './service-add-edit/ServiceDetail'

const blogBreadcrumbs: Array<PageLink> = [
  {
    title: 'Service Management',
    path: '/professional-services',
    isSeparator: false,
    isActive: false,
  },
]

const ServicePage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <PageTitle breadcrumbs={blogBreadcrumbs}>Service list</PageTitle>
            <Outlet />
          </>
        }
      >
        <Route
          path={`/`}
          element={
            <>
              <ServiceListWrapper />
            </>
          }
        />
        <Route
          path={`/detail`}
          element={
            <>
              <ServiceDetail />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ServicePage
