import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  amount?: number
}

const Amount: FC<Props> = ({amount}) => (
  <div className='d-flex flex-column '>
    <p className='mb-1 text-sm pl-1'>${amount}</p>
  </div>
)

export {Amount}
