import React, {FC} from 'react'
import style from '../../../modules/jobs/jobsStyle.module.css'
const OfferProductSection = ({title = 'My Products', products}) => {
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>{title}</h3>
      </div>
      <div className='card-body pt-3'>
        <div className='text-gray-800 mb-5'>
          <div>
            <div className='d-flex overflow-auto fw-bold align-items-center text-gray-600   mb-2'>
              {products.products.map((product, index) => (
                <span key={index} className={style.skillstag}>
                  {product}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {OfferProductSection}
