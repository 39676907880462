import React from 'react'

const SocialMedia = ({socialInfo}) => {
  return (
    <div className='d-flex flex-row align-items-center justify-content-between'>
      <div className=''>
        <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
          Follow Us On:
        </a>
      </div>
      <div className='d-flex align-items-center ml-auto '>
        {socialInfo.facebook ? (
          <a
            href={socialInfo.facebook}
            className='btn btn-icon btn-light-facebook btn-sm me-3'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='bi bi-facebook fs-4'></i>
          </a>
        ) : null}
        {socialInfo.instagram ? (
          <a
            href={socialInfo.instagram}
            className='btn btn-icon btn-light-instagram btn-sm me-3'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='bi bi-instagram fs-4'></i>
          </a>
        ) : null}
        {socialInfo.youtube ? (
          <a
            href={socialInfo.youtube}
            className='btn btn-icon btn-light-youtube btn-sm me-3'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='bi bi-youtube fs-4'></i>
          </a>
        ) : null}
      </div>
    </div>
  )
}

export {SocialMedia}
