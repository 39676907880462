// import XLSX from 'xlsx';
import {read, utils} from 'xlsx'
import {format, parse, parseISO} from 'date-fns'
import {isEmpty, padStart} from 'lodash'
const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = () => {
      reject('Error converting file to Base64')
    }
    reader.readAsDataURL(file)
  })
}
// is trashed reocrd
export const isTrashed = (url) => {
  if (!url) return false
  const params = new URLSearchParams(url)
  return params.get('filter_trashedstatus') === 'trashed'
}
export const joinWithComma = (...strings) => {
  return strings.filter((str) => str !== undefined).join(', ')
}
export const joinWithSpace = (...strings) => {
  return strings.filter((str) => str !== undefined).join(' ')
}

export const isBase64Image = (str) => {
  return /^data:image\/[^;]+;base64,/.test(str)
}
export const splitArrayByType = <T>(
  inputArray: T[],
  typeToCheck: string
): {matching: T[]; nonMatching: T[]} => {
  const matching: T[] = []
  const nonMatching: T[] = []

  inputArray.forEach((item) => {
    if (typeof item === typeToCheck) {
      matching.push(item)
    } else {
      nonMatching.push(item)
    }
  })

  return {matching, nonMatching}
}
const formatDateRange = (inputDate: string, endDateString: string): string => {
  const startDate = parse(inputDate, 'dd/MM/yyyy', new Date())
  const endDate = parse(endDateString, 'dd/MM/yyyy', new Date())

  const formattedStartDate = format(startDate, 'MMM dd, yyyy', {
    useAdditionalDayOfYearTokens: true,
  })
  const formattedEndDate = format(endDate, 'MMM dd, yyyy', {
    useAdditionalDayOfYearTokens: true,
  })
  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`
  return formattedDateRange
}
const formatDate = (inputDate: string): string => {
  const startDate = parse(inputDate, 'dd/MM/yyyy', new Date())

  const formattedDate = format(startDate, 'MMM dd, yyyy', {
    useAdditionalDayOfYearTokens: true,
  })
  return formattedDate
}
// export const formatProductOptions = (productsArray) => {
//   return productsArray.map((item) => ({

//   }))
// }
export const formatTimeString = (isoDate) => {
  const parsedDate = parseISO(isoDate)
  const formattedDate = format(parsedDate, `hh:mm a`)
  return formattedDate
}

const formatDateNew = (dateStr) => {
  const inputDate = new Date(dateStr)
  const formattedDate = format(inputDate, 'MMM dd, yyyy')
  return formattedDate
}
const getTimeAgo = (date: Date): string => {
  if (!date) {
    return 'Unknown'
  }

  const now = new Date()
  const elapsedMilliseconds = now.getTime() - date.getTime()
  let timeAgo = ''

  if (elapsedMilliseconds < 1000) {
    // Less than a second
    timeAgo = `${Math.floor(elapsedMilliseconds)} sec`
  } else if (elapsedMilliseconds < 60000) {
    // Less than a minute
    timeAgo = `${Math.floor(elapsedMilliseconds / 1000)} sec`
  } else if (elapsedMilliseconds < 3600000) {
    // Less than an hour
    timeAgo = `${Math.floor(elapsedMilliseconds / 60000)} min`
  } else if (elapsedMilliseconds < 86400000) {
    // Less than a day
    timeAgo = `${Math.floor(elapsedMilliseconds / 3600000)} hours`
  } else if (elapsedMilliseconds < 2592000000) {
    // Less than a month (30 days)
    timeAgo = `${Math.floor(elapsedMilliseconds / 86400000)} days`
  } else if (elapsedMilliseconds < 31536000000) {
    // Less than a year (365 days)
    timeAgo = `${Math.floor(elapsedMilliseconds / 2592000000)} months`
  } else {
    // More than a year
    timeAgo = `${Math.floor(elapsedMilliseconds / 31536000000)} years`
  }

  return `${timeAgo} ago`
}

async function convertExcelToJson(base64Data: string): Promise<any[] | null> {
  try {
    const buffer = Buffer.from(base64Data, 'base64')
    const workbook = read(buffer, {type: 'buffer'})

    // Assuming the Excel file has a single sheet
    const sheetName = workbook.SheetNames[0]
    const sheet = workbook.Sheets[sheetName]

    // Parse the sheet data to JSON
    const jsonData = utils.sheet_to_json(sheet)

    return jsonData
  } catch (error) {
    console.error('Error converting Excel to JSON:', error)
    return null
  }
}
export const extractVideoValues = (formattedVideos) => {
  if (!formattedVideos || formattedVideos.length <= 0) return []
  return formattedVideos.map((videoObj) => videoObj.value)
}
export const formatVideos = (videos) => {
  if (!videos || videos.length <= 0) return
  return videos.map((video) => ({value: video}))
}
export const formatPhoneNumber = (str) => {
  const cleaned = str.replace(/\D/g, '')
  const isAlreadyFormatted = /^\(\d{3}\) \d{3}-\d{4}$/g.test(str)
  if (isAlreadyFormatted) {
    return str.trim()
  } else {
    // Check if it's a valid phone number
    const isValid = /^\d{10}$/g.test(cleaned)
    if (isValid) {
      // Format the number as (XXX) XXX-XXXX
      const formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`
      return formatted
    }
  }
}
export const reversePhoneNumber = (str) => {
  const cleaned = str.replace(/\D/g, '')

  let areaCode = ''
  let phoneNumber = ''
  if (cleaned.startsWith('1')) {
    const match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      areaCode = match[1]
      phoneNumber = match[2] + match[3]
    }
  } else {
    areaCode = cleaned.slice(0, 3)
    phoneNumber = cleaned.slice(3)
  }
  return `+1${areaCode}${phoneNumber}`
}
export const handleKeyPress = (event) => {
  const key = event.keyCode || event.which
  if (key < 48 || key > 57) {
    event.preventDefault()
  }
}
export {
  convertFileToBase64,
  formatDateNew,
  getTimeAgo,
  formatDateRange,
  formatDate,
  convertExcelToJson,
}
