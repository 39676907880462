import React, {useState} from 'react'
import {useListView} from '../../../apps/business-management/core/ListViewProvider'
import {useParams, useSearchParams} from 'react-router-dom'
import {RatingEditModal} from './edit-rating/RatingEditModal'
import {useRatingModal} from './edit-rating/RatingContext'

const AvgRatingReview = ({businessRating, reviews}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {modalStatus, toggleModal} = useRatingModal()
  const [params, _] = useSearchParams()
  const businessId = params.get('Id') ? params.get('Id') : ''
  const fullStars: number = Math.floor(businessRating)
  const hasHalfStar: boolean = businessRating % 1 !== 0

  const stars: React.ReactNode[] = []

  if (businessRating === 0) {
    for (let i = 1; i <= 5; i++) {
      stars.push(<i key={`empty${i}`} className='bi bi-star me-2 text-warning fs-1'></i>)
    }
  } else {
    for (let i = 1; i <= fullStars; i++) {
      stars.push(<i key={i} className='bi bi-star-fill me-2 text-warning fs-1'></i>)
    }

    if (hasHalfStar) {
      stars.push(<i key='half' className='fa fa-star-half-alt me-2 text-warning fs-1'></i>)
    }

    const emptyStars: number = 5 - (fullStars + (hasHalfStar ? 0.5 : 0))
    for (let i = 1; i <= emptyStars; i++) {
      stars.push(<i key={`empty${i}`} className='bi bi-star me-2 text-warning fs-1'></i>)
    }
  }

  const openUpdateRatingModal = () => {
    setItemIdForUpdate(businessId)
    document.body.classList.add('modal-open')
    toggleModal(true)
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack'>
        <div className='d-flex flex-column flex-grow-1 pe-8'>
          <div className='d-flex flex-wrap'>
            <div className='d-flex align-items-center'>
              <div className='me-6'>
                {stars}
                <span className='text-gray-800 fw-bold'>{businessRating}</span>
              </div>
            </div>

            {reviews.length ? (
              <div className='border border-gray-300 border-dashed  rounded min-w-25px py-2 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='fs-2 fw-bolder text-center'>{reviews.length}</div>
                </div>
                <div className='fw-bold fs-6 text-gray-400 text-center'>Reviews</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className=''>
          <button type='button' className='btn btn-primary' onClick={openUpdateRatingModal}>
            Update Rating
          </button>
        </div>
      </div>
      {modalStatus ? <RatingEditModal /> : null}
    </>
  )
}

export {AvgRatingReview}
