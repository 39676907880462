import axios from 'axios'
import {IUpdateEmail} from '../SettingModels'
// import {IUpdateEmail, IUpdatePassword} from './SettingModels'

const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/auth/verify_token`
export const UPDATE_EMAIL = `${API_URL}/auth/update_email`
export const UPDATE_PASSWORD = `${API_URL}/auth/update_password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset_password`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`

// Server should return AuthModel
export function update_email(newEmail: string, currentEmail: string) {
  return axios.post<IUpdateEmail>(UPDATE_EMAIL, {
    newEmail,
    currentEmail,
  })
}
export function update_password(currentPassword: string, newPassword: string) {
  return axios.post<IUpdateEmail>(UPDATE_PASSWORD, {
    currentPassword,
    newPassword,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return AuthModel
export function updatePassword(password: string, token: string, userId: string) {
  return axios.post(RESET_PASSWORD_URL, {
    password,
    token,
    userId,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  // return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL,config);
  return axios.get(GET_USER_BY_ACCESS_TOKEN_URL, config)
}
