import React, {FC} from 'react'

const Descriptions = ({title = 'Description', description}) => {
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>{title}</h3>
      </div>
      <div className='card-body pt-3'>
        {description?.business_name && (
          <div className='text-gray-800 mb-5'>
            <span>
              <b>Business Name : </b>
            </span>
            <span>{description.business_name}</span>
          </div>
        )}

        {description?.subject && (
          <div className='text-gray-800 mb-5'>
            <span>
              <b>Subject : </b>
            </span>
            <span>{description.subject}</span>
          </div>
        )}
        <div className='text-gray-800 mb-5'>
          <span>
            <b>Message : </b>
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: description?.message,
            }}
          ></span>
        </div>
      </div>
    </div>
  )
}

export {Descriptions}
