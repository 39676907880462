import {useQuery} from 'react-query'
import {PlanEditModalForm} from './PlanEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getPlanById} from '../core/_requests'

const PlanEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: plan,
    error,
  } = useQuery(
    `${QUERIES.PLAN_LIST}-${itemIdForUpdate}`,
    () => {
      const plan = getPlanById(itemIdForUpdate)
      return plan
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <PlanEditModalForm isLoading={isLoading} plan={{id: undefined}} />
  }
  if (!isLoading && !error && plan) {
    return <PlanEditModalForm isLoading={isLoading} plan={plan} />
  }

  return null
}

export {PlanEditModalFormWrapper}
