import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  status?: string
}

const StatusCell: FC<Props> = ({status}) =>{ 
  const getStatusStyle = (value) =>{
    return {
      "incomplete":"badge-light-info",
      "requires_confirmation":"badge-light-primary",
      "requires_capture":"badge-light-info",
      "processing":"badge-light-warning",
      "succeeded":'badge-light-success',
      "canceled":"badge-light-danger",
      "requires_payment_method":"bg_light_gray"
    }[value]
  }
  return(
  <>
    {status && (
      <div
        className={`badge fw-bolder ${
          getStatusStyle(status)
        }`}
      >
        {status}
      </div>
    )}
  </>
)
      }
export {StatusCell}
