/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {BusinessCategoryListWrapper} from '../../modules/business-list/BusinessCategoryList'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

const BusinessCategoryPage: FC = () => {
  const offersBreadcrum: Array<PageLink> = [
    {
      title: 'Business Categories',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={offersBreadcrum}>Business Category list</PageTitle>
      <BusinessCategoryListWrapper />
    </>
  )
}

export {BusinessCategoryPage}
