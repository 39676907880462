import React from 'react'
import style from '../../jobs/jobsStyle.module.css'
const FaqQnaData = ({detailsfqna}) => {
  return (
    <>
      <div className='card mb-5 mb-xxl-8'>
        <div className='card-header border-1'>
          <h3 className='card-title fw-bold text-dark'>Detailed View Question and Answer</h3>
        </div>
      </div>
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='row'>
                  <div className='col-lg-12 mb-2'>
                    <span className='fw-bold fs-6 mb-2 pe-2'>Question : </span>
                    <span className='fw-bold align-items-center fs-6 mb-2 pe-2 mb-2'>
                      {detailsfqna.question}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <span className='fw-bold fs-6 mb-2 pe-2'>Answer : </span>
                    <span className='fw-bold align-items-center text-gray-600   mb-2'>
                      {detailsfqna.answer}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default FaqQnaData
