import {useQuery} from 'react-query'
import {BusinessCommentEditModalForm} from './BusinessCommentEditModalForm'
import {isNotEmpty, QUERIES, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getBusinessCommentById} from '../core/_requests'
import {useState} from 'react'
import {useQueryRequest} from '../../../blog-category/core/QueryRequestProvider'
import {useSearchParams} from 'react-router-dom'

const BusinessCommentEditModalFormWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const Id = searchParams.get('Id')

  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: businessComment,
    error,
  } = useQuery(
    `${QUERIES.BUSINESS_COMMENTS}-${itemIdForUpdate}`,
    () => {
      const businessComment = getBusinessCommentById(Id, itemIdForUpdate)
      return businessComment
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <BusinessCommentEditModalForm isLoading={isLoading} businessComment={{id: undefined}} />
  }
  if (!isLoading && !error && businessComment) {
    return <BusinessCommentEditModalForm isLoading={isLoading} businessComment={businessComment} />
  }

  return null
}

export {BusinessCommentEditModalFormWrapper}
