import React from 'react'

import {Link, useSearchParams} from 'react-router-dom'
// import {TimeTable} from '../data-table/components/view-pages/TimeTable'
import style from './jobsStyle.module.css'
// import {ContactInfo} from '../data-table/components/view-pages/ContactInfo'
import {useQuery} from 'react-query'
import {getFaqQnaById} from '../core/_requests'
import {QUERIES, isNotEmpty} from '../../../../_metronic/helpers'
import FaqQnaData from '../faq-qna-add-edit/FaqQnaData'

const FaqQnaDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const Id = searchParams.get('Id')

  const enabledQuery: boolean = isNotEmpty(Id)
  const {
    isLoading,
    data: detailsfqna,
    error,
  } = useQuery(
    `${QUERIES.FAQ_QNA_URL}-${Id}`,
    () => {
      return getFaqQnaById(Id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  if (isLoading) return <p>Loading...</p>

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <FaqQnaData detailsfqna={detailsfqna} />
        </div>
        <div className='col-lg-4'></div>
      </div>
    </>
  )
}

export default FaqQnaDetails
