import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PlanListWrapper} from './PlanList'

const planBreadcrumbs: Array<PageLink> = [
  {
    title: 'Plans Management',
    path: '/plans/',
    isSeparator: false,
    isActive: false,
  },
]

const PlanPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={planBreadcrumbs}>Plans list</PageTitle>
      <PlanListWrapper />
    </>
  )
}

export default PlanPage
