import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../auth'
import {useQuery} from 'react-query'
import {KTIcon, QUERIES, stringifyRequestQuery} from '../../../../_metronic/helpers'
import {getEmailVerification, getResendEmailVerification} from '../../management/core/_requests'
import {useQueryRequest} from '../../management/core/QueryRequestProvider'

export function Overview() {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(stringifyRequestQuery(state))
  const {currentUser} = useAuth()
  const {
    isFetching: resendIsFetching,
    data: resendResponse,
    refetch: resendRefetch,
  } = useQuery(
    `${QUERIES.GEN_EMAIL_URL}-${query}`,
    () => {
      return getResendEmailVerification(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: false}
  )

  const emailVerified = currentUser?.emailVerified

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>

          <Link to='../setting' className='btn btn-primary align-self-center'>
            Edit Profile
          </Link>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {' '}
                {currentUser?.first_name} {currentUser?.last_name}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Email
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Email must be verified'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{currentUser?.email}</span>

              {emailVerified ? (
                <span className='fw-bolder fs-6 me-2  '>
                  <span className='badge badge-success'>Verified</span>
                </span>
              ) : (
                <>
                  {emailVerified && <span>Loading verification status...</span>}
                  {!emailVerified && (
                    <span>
                      <span className='fw-bolder fs-6 me-2  '>
                        <span className='badge badge-light-danger'>not verified</span>
                      </span>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault()
                          resendRefetch()
                        }}
                      >
                        Send verification email
                      </a>
                      {resendIsFetching && <span> (sending...)</span>}
                      {resendResponse && <span> (sent successfully)</span>}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Contact No.</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{currentUser?.phone}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
