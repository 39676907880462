import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FaqQna} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createFaqQna, updateFaqQnaCategory} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Loading} from '../components/loading/Loading'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type Props = {
  isUserLoading: boolean
  faqQna: FaqQna
}

const editUserSchema = Yup.object().shape({
  question: Yup.string().required('Question is required'),
  answer: Yup.string().required('Answer is required'),
})

const FaqQnaEditModalForm: FC<Props> = ({faqQna, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<FaqQna>({
    ...faqQna,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateFaqQnaCategory(values)
          cancel(true)
        } else {
          await createFaqQna(values)
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Question</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Question'
            {...formik.getFieldProps('question')}
            type='text'
            name='question'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.question && formik.errors.question},
              {
                'is-valid': formik.touched.question && !formik.errors.question,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.question && formik.errors.question && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.question}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Answer</label>
          {/* <textarea
            placeholder='Answer'
            {...formik.getFieldProps('answer')}
            name='answer'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.answer && formik.errors.answer},
              {'is-valid': formik.touched.answer && !formik.errors.answer}
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
            rows={4} // Set the number of visible rows
          /> */}
          <ReactQuill
            value={formik.values.answer}
            onChange={(value) => formik.setFieldValue('answer', value)}
            modules={{toolbar: true}}
          />

          {formik.touched.answer && formik.errors.answer && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.answer}</span>
              </div>
            </div>
          )}
        </div>
        <label className=' fw-bold fs-6 mb-2'>
          Fields marked with <span className='required'></span> are mandatory
        </label>
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <Loading />}
    </>
  )
}

export {FaqQnaEditModalForm}
