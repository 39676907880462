import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const MANAGEMENT_URL = `${API_URL}/content_setting/general_setting`
const ALL_DATA_URL = `${API_URL}/content_setting/stats`
const FOOTER_MANAGE_URL = `${API_URL}/content_setting/footer_links`
const SLIDER_URL = `${API_URL}/content_setting/slider`
const NOTIFICATION_URL = `${API_URL}/content_setting/notification`
const EMAILVERIFICATION_URL = `${API_URL}/auth/email_verification`
const RESENDEMAILVERIFICATION_URL = `${API_URL}/auth/resend_email_verification`

const getJobs = () => {
  return axios.get(`${MANAGEMENT_URL}`).then(({data}) => {
    return data
  })
}
const getAllFooters = () => {
  return axios.get(`${FOOTER_MANAGE_URL}`).then(({data}) => {
    return data
  })
}

const getResendEmailVerification = (query: string) => {
  return axios.get(`${RESENDEMAILVERIFICATION_URL} `).then(({data}) => {
    return data
  })
}

const getEmailVerification = (data) => {
  return axios
    .post(`${EMAILVERIFICATION_URL}`, data)
    .then(({data}) => {
      return data
    })
    .catch((error) => {
      console.error('Error during POST request:', error)
      throw error
    })
}

const getJobById = (id) => {
  return axios.get(`${MANAGEMENT_URL} `).then(({data}) => {
    return data.data
  })
}
const getAllDataById = (id) => {
  return axios.get(`${ALL_DATA_URL} `).then(({data}) => {
    return data.data
  })
}
const getAllFooterid = (id) => {
  return axios.get(`${FOOTER_MANAGE_URL} `).then(({data}) => {
    return data.data
  })
}
const getSlider = (id) => {
  return axios.get(`${SLIDER_URL} `).then(({data}) => {
    return data.data
  })
}

const createJob = (job) => {
  return axios
    .post(MANAGEMENT_URL, job)
    .then((response) => response.data)
    .then((response) => response.data)
}
const createSlider = (slider) => {
  return axios
    .post(SLIDER_URL, slider)
    .then((response) => response.data)
    .then((response) => response.data)
}
const createFooter = (footer) => {
  return axios
    .post(FOOTER_MANAGE_URL, footer)
    .then((response) => response.data)
    .then((response) => response.data)
}

// const updateJob = (manageMent) => {
const updateJob = async (jobId, values) => {
  return axios
    .put(`${MANAGEMENT_URL}/${jobId}`, values)
    .then((response) => response.data)
    .then((response) => response.data)
}
const updateFooter = async (jobId, values) => {
  return axios
    .put(`${FOOTER_MANAGE_URL}/${jobId}`, values)
    .then((response) => response.data)
    .then((response) => response.data)
}
const updateSlider = async (sliderId, values) => {
  return axios
    .put(`${SLIDER_URL}/${sliderId}`, values)
    .then((response) => response.data)
    .then((response) => response.data)
}

const deleteJob = (jobId) => {
  return axios.delete(`${MANAGEMENT_URL}/${jobId}`).then(() => {})
}
const deleteFooter = (jobId) => {
  return axios.delete(`${FOOTER_MANAGE_URL}/${jobId}`).then(() => {})
}
const deleteSlider = (jobId) => {
  return axios.delete(`${SLIDER_URL}/${jobId}`).then(() => {})
}

const deleteSelectedOffersTypes = (jobIds) => {
  const requests = jobIds.map((id) => axios.delete(`${MANAGEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
// upload bulk jobs
const importJobs = (bulkData) => {
  return axios
    .post(`${MANAGEMENT_URL}/admin`, bulkData)
    .then((response) => response.data)
    .then((response) => response.data)
}

const addContactDetails = (data) => {
  return axios
    .post(`${API_URL}/content_setting/contact_us`, data)
    .then((response) => response.data)
    .then((response) => response.data)
}
const getContactDetails = () => {
  return axios
    .get(`${API_URL}/content_setting/contact_us`)
    .then((response) => response.data)
    .then((response) => response.data)
}
const updateContactDetails = (data) => {
  return axios
    .put(`${API_URL}/content_setting/contact_us/${data.id}`, data)
    .then((response) => response.data)
    .then((response) => response.data)
}
const addOfferContent = (data) => {
  return axios
    .post(`${API_URL}/content_setting/offer_content`, data)
    .then((response) => response.data)
    .then((response) => response.data)
}
const getOfferContent = () => {
  return axios
    .get(`${API_URL}/content_setting/offer_content`)
    .then((response) => response.data)
    .then((response) => response.data)
}
const updateOfferContent = (data) => {
  return axios
    .put(`${API_URL}/content_setting/offer_content/${data.id}`, data)
    .then((response) => response.data)
    .then((response) => response.data)
}

const getAllNotification = () => {
  return axios.get(NOTIFICATION_URL).then(({data}) => {
    return data.data
  })
}
const clearAllNotification = (typeArr) => {
  const requests = typeArr.map((item) => axios.put(`${NOTIFICATION_URL}`, {notificationType: item}))
  return axios.all(requests).then(() => {})
}
const clearOneNotification = (type) => {
  return axios.put(`${NOTIFICATION_URL}`, {notificationType: type}).then(() => {})
}

export {
  deleteJob,
  createFooter,
  clearAllNotification,
  clearOneNotification,
  deleteSelectedOffersTypes,
  updateJob,
  getJobById,
  createJob,
  importJobs,
  getJobs,
  getAllFooters,
  getEmailVerification,
  getResendEmailVerification,
  getAllFooterid,
  updateFooter,
  deleteFooter,
  createSlider,
  getSlider,
  updateSlider,
  deleteSlider,
  getAllDataById,
  getAllNotification,
  addContactDetails,
  getContactDetails,
  updateContactDetails,
  updateOfferContent,
  getOfferContent,
  addOfferContent
}
