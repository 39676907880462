import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  status?: string
}

const StatusCell: FC<Props> = ({status}) => (
  <>
    {' '}
    {status && (
      <div
        className={`badge fw-bolder ${
          status === 'Received'
            ? 'badge-light-info'
            : `${status === 'Resolved' ? 'badge-light-success' : 'badge-light-primary'}`
        }`}
      >
        {status}
      </div>
    )}
  </>
)
export {StatusCell}
