/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {BusinessCategory} from '../../core/_models'
import {ID, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {useBusinesses} from '../../../../../_metronic/layout/components/sidebar/sidebar-menu/menuData'
import _ from 'lodash'
type Props = {
  offer: BusinessCategory
  id: ID
  // avatar:string
}

const InfoCell: FC<Props> = ({id, offer}) => {
  const navigate = useNavigate()

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const viewAllBusinessHandler = () => {
    const urlData = _.replace(offer.url, /%20/g, ' ')
    navigate(`/businesses/${offer.id}/${urlData}`)
  }
  return (
    <div onClick={viewAllBusinessHandler} className='d-flex align-items-center pointer'>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a>
          <div className='symbol-label'>
            <img src={offer.avatar ? offer.avatar : blankImg} alt={offer?.name} className='w-100' />
          </div>
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{offer?.name}</a>
      </div>
    </div>
  )
}

export {InfoCell}
