import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'

import {KTCardBody} from '../../../_metronic/helpers'

import {faqQnaColumns} from './table/columns/_columns'
import {HeaderColumn} from './table/columns/HeaderColumn'
import {FaqQna} from './core/_models'
import {CustomRow} from './table/columns/CustomRow'
import {Pagination} from './components/pagination/Pagination'
import {Loading} from './components/loading/Loading'
import {useQueryResponseData, useQueryResponseLoading} from './core/QueryResponseProvider'

const FaqQnaTable = () => {
  const faqQnas = useQueryResponseData()

  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => faqQnas, [faqQnas])
  const columns = useMemo(() => faqQnaColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<FaqQna>) => (
                <HeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<FaqQna>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination />
      {isLoading && <Loading />}
    </KTCardBody>
  )
}

export {FaqQnaTable}
