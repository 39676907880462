import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/users`
const NEW_USER_URL = `${API_URL}/users`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`).then(({data}: AxiosResponse) => data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios.get(`${USER_URL}/${id}`).then(({data}: AxiosResponse) => data.user)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(NEW_USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User) => {
  return axios
    .put(`${USER_URL}/${user.id}`, user)
    .then((response) => response.data)
    .then((response) => response.user)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}
const restoreUser = (userId: ID): Promise<void> => {
  return axios.get(`${USER_URL}/restore/${userId}`).then(() => {})
}

const updateUserProfilePic = (userId: ID, imageData) => {
  return axios
    .post(`${USER_URL}/${userId}/profile_update`, imageData)
    .then((response) => response.data)
    .then((response) => response.user)
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const restoreSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.get(`${USER_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}
export {
  getUsers,
  deleteUser,
  restoreSelectedUsers,
  restoreUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUserProfilePic,
  updateUser,
}
