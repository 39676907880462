/* eslint-disable jsx-a11y/anchor-is-valid */


export function Campaigns() {
  return (
    <>
        <h3 className='fw-bolder my-2'>
          My Campaigns
        </h3>
    </>
  )
}
