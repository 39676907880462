import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {BusinessComment} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createBusinessComment, updateBusinessComment} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Loading} from '../components/loading/Loading'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {useSearchParams} from 'react-router-dom'
import {Rating} from 'react-simple-star-rating'
type Props = {
  isLoading: boolean
  businessComment: BusinessComment
}

const editBusinessComment = Yup.object().shape({
  comment: Yup.string().required('comment is required'),
  rating: Yup.string(),
})

const BusinessCommentEditModalForm: FC<Props> = ({businessComment, isLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [searchParams, _] = useSearchParams()
  const Id = searchParams.get('Id')
  const BusinessId = Id ? Id : ''
  const [BusinessForEdit] = useState<BusinessComment>({
    ...businessComment,
    rating: businessComment.rating,
  })
  const [rating, setRating] = useState(businessComment.rating || 0)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: BusinessForEdit,
    validationSchema: editBusinessComment,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(values.id)) {
          await updateBusinessComment(BusinessId, values)
          cancel(true)
        } else {
          await createBusinessComment(BusinessId, values)
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const updateRating = (rate) => {
    setRating(rate)
    formik.setFieldValue('rating', rate)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row'>
            <div className='col-lg-12'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Rating</label>
                <Rating initialValue={BusinessForEdit.rating} onClick={updateRating} />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Comment</label>
                <textarea
                  placeholder='Comment'
                  rows={3}
                  {...formik.getFieldProps('comment')}
                  name='comment'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
              </div>
            </div>
          </div>

          {businessComment.id ? (
            <div className=''>
              <div className='mb-7 d-flex fv-row align-items-center '>
              
                <label className='required fw-bold fs-6 me-10'>Status</label>
              
                <div className='d-flex fv-row align-items-center justify-content-around '>
                  
                  <div className='d-flex fv-row me-10'>
                  
                    <div className='form-check form-check-custom form-check-solid'>
                      
                      <input
                        className='form-check-input me-3'
                        {...formik.getFieldProps('status')}
                        name='status'
                        type='radio'
                        value='Accept'
                        id='kt_modal_update_role_option_0'
                        checked={formik.values.status === 'Accept'}
                        disabled={formik.isSubmitting || isLoading}
                      />
 
                      <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                        <div className='fw-bolder text-gray-800'>Accept</div>
                      </label> 
                    </div> 
                  </div>
                  <div className='d-flex fv-row'> 
                    <div className='form-check form-check-custom form-check-solid'>
                      
                      <input
                        className='form-check-input me-3'
                        {...formik.getFieldProps('status')}
                        name='status'
                        type='radio'
                        value='Reject'
                        id='kt_modal_update_role_option_0'
                        checked={formik.values.status === 'Reject'}
                        disabled={formik.isSubmitting || isLoading}
                      />
 
                      <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                        <div className='fw-bolder text-gray-800'>Reject</div>
                      </label> 
                    </div> 
                  </div> 
                </div> 
              </div>
            </div>
          ) : null}
        </div> 
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div> 
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {BusinessCommentEditModalForm}
