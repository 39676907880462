import React, {useState, useEffect, useCallback} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import CreatableSelect from 'react-select/creatable'
import clsx from 'clsx'
import FooterInfomationDetails from './details/FooterInformationDetails'
import {createFooter, getAllFooterid, updateFooter} from './core/_requests'
import {useQuery} from 'react-query'
import {QUERIES, stringifyRequestQuery} from '../../../_metronic/helpers'
import {useQueryRequest} from './core/QueryRequestProvider'
import {ToastContainer, toast} from 'react-toastify'
import {Loading} from './components/loading/Loading'
const validationSchema = Yup.object().shape({
  pageName: Yup.string().required(' Page Name is Required'),
  // pageLink: Yup.string().required('url is required'),
  pageCategory: Yup.string().required('Select Page Category  '),
})

const FooterCotegeryFormLink: React.FC = () => {
  type Option = {value: string; label: string}
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(stringifyRequestQuery(state))
  const [loading1, setLoading1] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const {
    isLoading,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.FTR_QNA_URL}-${query}`,
    () => {
      return getAllFooterid(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const responseData = response

  const [formValues, setFormValues] = useState({
    pageName: '',
    pageLink: '',
    pageCategory: '',
  })

  const [selectOptions, setSelectOptions] = useState<Option[]>([])

  useEffect(() => {
    if (responseData) {
      // Generate options array from response data
      const options: Option[] = Array.from(
        new Set<string>(responseData.map((item) => item.pageCategory))
      ).map((category) => ({
        value: category,
        label: category,
      }))

      setSelectOptions(options)
    }
  }, [responseData])

  const handleEdit = (data) => {
    setFormValues({
      pageName: data.pageName || '',
      pageLink: data.pageLink || '',
      pageCategory: data.pageCategory || '',
    })
    setSelectedItemId(data.id)
  }

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm, setFieldValue}) => {
      setSubmitting(true)
      setLoading1(true)
      try {
        if (selectedItemId) {
          await updateFooter(selectedItemId, values)
        } else {
          await createFooter(values)
        }
        refetch()
        setFieldValue('pageCategory', '')
        resetForm()
      } catch (ex) {
        console.error(ex)
        toast.error(`Error updating slider: ${ex}`, {position: 'top-right'})
      } finally {
        setSubmitting(false)
        setSelectedItemId(null)
        setLoading1(false)
        setFormValues({
          pageName: '',
          pageLink: '',
          pageCategory: '',
        })
      }
    },
  })

  useEffect(() => {
    if (selectedItemId) {
      const selectedItem = responseData.find((item) => item.id === selectedItemId)
      formik.setValues({
        pageName: selectedItem?.pageName || '',
        pageLink: selectedItem?.pageLink || '',
        pageCategory: selectedItem?.pageCategory || '',
      })
    }
  }, [selectedItemId, responseData, formik.setValues])

  if (isLoading) return <Loading />
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'> Footer Links</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top p-9'>
            <form
              onSubmit={formik.handleSubmit}
              id='kt_signin_change_email'
              className='form'
              noValidate
            >
              <div className='row mb-6'>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='pageCategory' className='form-label fs-6 fw-bolder mb-3'>
                      Select List Type
                    </label>

                    <CreatableSelect
                      isClearable
                      onChange={(newValue, actionMeta) => {
                        if (newValue && actionMeta.action === 'create-option') {
                          const newOption: Option = {
                            value: (newValue as Option).value,
                            label: (newValue as Option).label,
                          }
                          setSelectOptions((options) => [newOption, ...options])
                        }

                        formik.handleChange({
                          target: {
                            name: 'pageCategory',
                            value: newValue ? (newValue as Option).value : '',
                          },
                        })
                        formik.handleBlur('pageCategory')
                      }}
                      options={selectOptions}
                      value={selectOptions.find(
                        (option) => option.value === formik.values.pageCategory
                      )}
                    />

                    {formik.touched.pageCategory && formik.errors.pageCategory && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pageCategory}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='pageName' className='form-label fs-6 fw-bolder mb-3'>
                      Page Name
                    </label>
                    <input
                      type='text'
                      className=' required form-control form-control-lg form-control-solid'
                      id='pageName'
                      name='pageName'
                      placeholder='Enter Footer page Name '
                      value={formik.values.pageName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.pageName && formik.errors.pageName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pageName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='pageLink' className='form-label fs-6 fw-bolder mb-3'>
                      Page Url
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='pageLink'
                      name='pageLink'
                      placeholder='Enter  Page Url'
                      value={formik.values.pageLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.pageLink && formik.errors.pageLink && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pageLink}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <button id='kt_signin_submit' type='submit' className='btn btn-primary  me-2 px-6'>
                  {!loading1 && 'Update Footer'}
                  {loading1 && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>

            <div className='separator separator-dashed my-6'></div>
          </div>
        </div>
      </div>
      <div>
        <FooterInfomationDetails
          responseData={responseData}
          onEdit={handleEdit}
          refetch={refetch}
        />
      </div>
      <ToastContainer />
    </>
  )
}

export {FooterCotegeryFormLink}
