import React from 'react'
import {isEmpty} from 'lodash'
import {formatTimeString} from '../../../../../utils/helperFn'

const TimeTable = ({timeTableInfo}) => {
  const {_id, ...filteredData} = timeTableInfo
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>Open Hours</h3>
      </div>
      <div className='card-body pt-2'>
        <table className='table table-striped '>
          <tbody>
            {Object.keys(filteredData).map((day) => (
              <tr key={day}>
                <td>{day}</td>
                <td>
                  {filteredData[day].from && filteredData[day].to ? (
                    <>
                      {formatTimeString(filteredData[day].from)} -
                      {formatTimeString(filteredData[day].to)}
                    </>
                  ) : (
                    <>Closed</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export {TimeTable}
