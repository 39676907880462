import {Description} from '../../data-table/components/view-pages/Description'
import {QUERIES, isNotEmpty} from '../../../../_metronic/helpers'
import {useSearchParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {getEnquiryById} from '../core/_requests'
import {Loading} from '../components/loading/Loading'
import {EnquiryHeader} from '../../data-table/components/view-pages/EnquiryHeader'
import {Descriptions} from '../../data-table/components/view-pages/Descriptions'

const EnquiryDetailPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const Id = searchParams.get('Id')
  const enabledQuery: boolean = isNotEmpty(Id)
  const {
    isLoading,
    data: enquiry,
    error,
  } = useQuery(
    `${QUERIES.ENQUIRY_LIST}-${Id}`,
    () => {
      return getEnquiryById(Id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  if (isLoading) return <Loading />

  return (
    <>
      <EnquiryHeader dataInfo={enquiry} />
      {enquiry?.message ? <Descriptions title='Enquiry Detail' description={enquiry} /> : null}
    </>
  )
}

export {EnquiryDetailPage}
