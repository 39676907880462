// @ts-nocheck
import { Column } from 'react-table'
import { InfoCell } from './InfoCell'

import { StatusCell } from './StatusCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { RealEstate } from '../../core/_models'
import { DateCell } from './DateCell'
import { format, parseISO } from 'date-fns'
import { Transaction } from './Transaction'
import { PlanDetails } from './PlanDetails'
import { Amount } from './Amount'

const plansColumns: ReadonlyArray<Column<RealEstate>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Customer' className='min-w-125px' />,
    id: 'Name',
    Cell: ({ ...props }) => <InfoCell user={props.data[props.row.index].userId} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Plan' className='min-w-125px' />,
    id: 'plan',
    Cell: ({ ...props }) => <PlanDetails plan={props.data[props.row.index].planId} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Amount' className='min-w-125px' />,
    id: 'amount',
    Cell: ({ ...props }) => <Amount amount={props.data[props.row.index].amount} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({ ...props }) => {
      const dateString: String = props.data[props.row.index].createdAt
      const parsedDate = parseISO(dateString)
      const formattedDate = format(parsedDate, 'MMM dd, yyyy')
      return <DateCell date={formattedDate} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Expired Date' className='min-w-125px' />
    ),
    id: 'expiredAt',
    Cell: ({ ...props }) => {
      const dateString: String = props.data[props.row.index].expiredAt
      if (dateString) {
        const parsedDate = parseISO(dateString)
        const formattedDate = format(parsedDate, 'MMM dd, yyyy')
        return <DateCell date={formattedDate} />
      } else return <DateCell date={0} />
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='PaymentId' className='min-w-125px' />
    ),
    id: 'transactionId',
    Cell: ({ ...props }) => <Transaction paymentId={props.data[props.row.index].transactionId} />,
  },
]

export { plansColumns }
