import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PostCategory, initialCategory} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createPostCategory, updatePostCategory} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Loading} from '../components/loading/Loading'

type Props = {
  isUserLoading: boolean
  postCategory: PostCategory
}

const editUserSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

const PostCategoryEditModalForm: FC<Props> = ({postCategory, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<PostCategory>({
    ...initialCategory,
    ...postCategory,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const {createdAt, updatedAt, slug, ...restData} = values as any
          await updatePostCategory(restData)
        } else {
          await createPostCategory(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  const handleCommentChange = (isChecked) => {
    formik.setFieldValue('isCommentEnable', isChecked)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Name</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Title'
            {...formik.getFieldProps('name')}
            type='text'
            name='name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          <label htmlFor='description' className='form-label fs-6 fw-bolder mb-3'>
            Description
          </label>
          <textarea
            placeholder='Enter Description'
            className='form-control form-control-lg form-control-solid'
            id='description'
            {...formik.getFieldProps('description')}
            name='description'
            autoComplete='off'
          ></textarea>

          {formik.touched.description && formik.errors.description && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.description}</div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='  fw-bold fs-6 mb-2'> is Comment Enable ?</label>
          <div className='d-flex align-items-center radio_btn_wrap'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='flexSwitchCheckDefault'
                checked={!!formik.values.isCommentEnable}
                disabled={formik.isSubmitting || isUserLoading}
                name='isCommentEnable'
                onChange={(event) => handleCommentChange(event.target.checked)}
              />
              <label className='htmlForm-check-label' htmlFor='flexSwitchCheckDefault'>
                {formik.values.isCommentEnable ? 'Yes' : 'No'}
              </label>
            </div>
          </div>
        </div>

        {/* begin::Actions */}
        <label className=' fw-bold fs-6 mb-2'>
          Field marked with <span className='required'></span> are mandatory
        </label>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <Loading />}
    </>
  )
}

export {PostCategoryEditModalForm}
