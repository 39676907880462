import React, {useEffect, useState, useCallback} from 'react'
import {ContactForm} from './ContactForm'
import {useQuery} from 'react-query'
import {getOfferContent} from './core/_requests'
import {isEmpty} from 'lodash'
import {Loading} from '../offers types/components/loading/Loading'
import {OfferContent} from './OfferContent'

const OfferContentWrapper: React.FC = () => {
  const {
    isLoading,
    data: contactData,
    error,
  } = useQuery(
    `OfferContent`,
    () => {
      return getOfferContent()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  if (isLoading) return <Loading />
  if (isEmpty(contactData)) {
    return <OfferContent isLoading={isLoading} contentData={{id: undefined}} />
  }
  if (!isLoading && !error && contactData) {
    return <OfferContent isLoading={isLoading} contentData={contactData} />
  }

  return null
}

export {OfferContentWrapper}
