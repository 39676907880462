import React, { useEffect, useMemo, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PlanListWrapper } from '../PlanList'
import { getJobById, getJobs } from '../core/_requests'
import { QUERIES, stringifyRequestQuery } from '../../../../_metronic/helpers'
import { useQuery } from 'react-query'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { Loading } from '../components/loading/Loading'
// import { Table, Button } from 'reactstrap';

const SocialMediaDetails = () => {
  // Sample data for three rows
  const { state } = useQueryRequest()
  const [query, setQuery] = useState(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    isLoading,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.GEN_QNA_URL}-${query}`,
    () => {
      return getJobById(query)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  if (!response || !Array.isArray(response) || response.length === 0) {
    return <div>No data found</div>
  }

  const firstElement = response[0]

  // Additional checks to ensure the required properties are defined
  if (!firstElement || typeof firstElement !== 'object') {
    return <div>Invalid data format</div>
  }

  const companyLogo = firstElement.logo
  const companyFacebook = firstElement.facebook
  const companyInstagram = firstElement.instagram
  const companyYoutube = firstElement.youtube
  const companyIntro = firstElement.companyIntro
  const companylinkedIn = firstElement.linkedIn

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Social Media Details</h3>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company Logo</label>

            <div className='col-lg-8'>
              {/* Check if companyLogo is defined before rendering the image */}
              {companyLogo && (
                <img
                  src={companyLogo}
                  alt='Company Logo'
                  style={{
                    height: '80px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '5px',
                  }}
                />
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company Introduction </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {companyIntro}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Youtube
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{companyYoutube}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Instagram
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{companyInstagram}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Linkedin
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{companylinkedIn}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Facebook
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{companyFacebook}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialMediaDetails
