import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  images?: string
  videos?: string
}

const Media: FC<Props> = ({images, videos}) => (
  <div className='d-flex flex-column '>
    <p className='mb-1 text-sm'>
      {images} Images, {videos} Videos
    </p>
  </div>
)

export {Media}
