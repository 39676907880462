import React, {useMemo, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import {isArray} from 'lodash'
const blankImg = toAbsoluteUrl('/media/misc/auth-bg.png')
const ImageSection = ({images}) => {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const placeholderImage = {
    original: blankImg,
    thumbnail: blankImg,
  }

  const imageList = useMemo(() => {
    if (isArray(images) && images.length) {
      return images.map((item) => ({
        original: item,
        thumbnail: item,
      }))
    }
  }, [images])

  const settings = {
    infinite: false,
    showPlayButton: false,
    showBullets: false,
    showThumbnails: images?.length > 1 ? true : false,
  }

  const handleImageLoad = () => {
    setImagesLoaded(true)
  }

  if (!isArray(imageList)) return <p>Loading...</p>

  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>Images</h3>
      </div>
      <div className='card-body pt-3'>
        <div className='mb-5'>
          <div className='bgi-no-repeat bgi-size-cover rounded'>
            {!imagesLoaded && (
              <img src={placeholderImage.original} alt='Placeholder' height={350} width={600} />
            )}
            <ImageGallery
              {...settings}
              items={imageList}
              onImageLoad={handleImageLoad}
              additionalClass={imagesLoaded ? 'loaded' : 'loading'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {ImageSection}
