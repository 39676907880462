import {useQuery} from 'react-query'
import {ContactInfo} from '../../../../data-table/components/view-pages/ContactInfo'
import {Description} from '../../../../data-table/components/view-pages/Description'
import {DetailHeader} from '../../../../data-table/components/view-pages/DetailHeader'
import {ImageSection} from '../../../../data-table/components/view-pages/ImageSection'
import {useSearchParams} from 'react-router-dom'
import {QUERIES, isNotEmpty} from '../../../../../../_metronic/helpers'
import {getBusinessById} from '../../core/_requests'
import {Features} from '../../../../data-table/components/view-pages/Features'
import {VideosSection} from '../../../../data-table/components/view-pages/VideosSection'
import {TimeTable} from '../../../../data-table/components/view-pages/TimeTable'
import {isEmpty} from 'lodash'
import {ReviewList} from '../../../../data-table/components/view-pages/ReviewList'
import {Loading} from '../../../../business-list/components/loading/Loading'
import {
  RatingModalProvider,
  useRatingModal,
} from '../../../../data-table/components/view-pages/edit-rating/RatingContext'
import {BusinessCommentListWrapper} from '../../../business-reviews/BusinessCommentList'
import {ProductSection} from '../../../../data-table/components/view-pages/edit-rating/ProductSection'
const BusinessDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const Id = searchParams.get('Id')
  const enabledQuery: boolean = isNotEmpty(Id)
  const {modalStatus, toggleModal} = useRatingModal()
  const {
    isLoading,
    data: business,
    refetch,
    error,
  } = useQuery(
    `${QUERIES.BUSINESS_LIST}-${Id}`,
    () => {
      return getBusinessById(Id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  if (isLoading) return <Loading />

  return (
    <>
      <RatingModalProvider>
        <DetailHeader
          dataInfo={business}
          shopStatus={true}
          avgReviewRating={business?.averageRating ? true : false}
        />
      </RatingModalProvider>
      <div className='row'>
        <div className='col-lg-8'>
          {business?.description ? <Description description={business?.description} /> : null}
          {/* {business ? <ProductSection products={business} /> : null} */}
          {business?.amenities?.length ? <Features amenities={business?.amenities} /> : null}
          {business?.images?.length ? <ImageSection images={business?.images} /> : null}
          {business?.videos?.length ? <VideosSection videos={business?.videos} /> : null}
        </div>
        <div className='col-lg-4'>
          <ContactInfo contactInfo={business} />
          {business?.timetable ? <TimeTable timeTableInfo={business?.timetable} /> : null}
        </div>
      </div>
      <h3 className='card-title fw-bold text-dark pb-4'>Business Reviews</h3>
      <BusinessCommentListWrapper />
    </>
  )
}
export {BusinessDetail}
