import {FC, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Service, initialService} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createService, updateService} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Loading} from '../components/loading/Loading'
import {convertFileToBase64} from '../../../../utils/helperFn'
import toast, {Toaster} from 'react-hot-toast'

type Props = {
  isLoading: boolean
  service: Service
}

const editService = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('description is required'),
})

const ServiceEditModalForm: FC<Props> = ({service, isLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [serviceForEdit] = useState<Service>({
    ...initialService,
    ...service,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [selectedImage, setSelectedImage] = useState<string | null>()
  const formik = useFormik({
    initialValues: serviceForEdit,
    validationSchema: editService,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(values.id)) {
          await updateService(values)
          cancel(true)
        } else {
          await createService(values)
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const image = new Image()
      const imageUrl = URL.createObjectURL(file)
      image.src = imageUrl
      image.onload = async () => {
        const {width, height} = image
        if (width !== 400 || height !== 400) {
          URL.revokeObjectURL(imageUrl)
          return toast.error('Logo Must be 400x400 ')
        }
        setSelectedImage(imageUrl)
        const base64 = await convertFileToBase64(file)
        serviceForEdit.logo = base64
        formik.setFieldValue('logo', base64)
      }
      image.onerror = () => {
        toast.error('failed to load image')
      }
    }
  }

  const handleImageRemove = () => {
    setSelectedImage(null)
    serviceForEdit.logo = ''
  }

  return (
    <>
      <Toaster />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row'>
            <div className='col-lg-12'>
              <div className='fv-row mb-7 text-center'>
                {/* begin::Label */}
                <label className='d-block fw-bold fs-6 mb-5'>Logo</label>
                {/* end::Label */}

                {/* begin::Image input */}
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url('${blankImg}')`}}
                >
                  {/* begin::Preview existing avatar */}
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: `url('${
                        serviceForEdit?.logo ? serviceForEdit?.logo : selectedImage
                      }')`,
                    }}
                  ></div>
                  {/* end::Preview existing avatar */}

                  {/* begin::Label */}
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>

                    <input
                      type='file'
                      onChange={handleImageChange}
                      name='logo'
                      accept='.png, .jpg, .jpeg'
                    />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                  {/* end::Label */}

                  {/* begin::Cancel */}
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                    onClick={handleImageRemove}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                  {/* end::Cancel */}

                  {/* begin::Remove */}
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove avatar'
                    onClick={handleImageRemove}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                  {/* end::Remove */}
                </div>
                {/* end::Image input */}

                {/* begin::Hint */}
                <div className='form-text'>
                  Allowed file types: png, jpg, jpeg.Dimension 400x400px
                </div>
                {/* end::Hint */}
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Title</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Title'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Description</label>
                {/* end::Label */}
                <textarea
                  rows={4}
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid': formik.touched.description && formik.errors.description,
                    },
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  name='description'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                ></textarea>

                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.description}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}
        <label className=' lbl_styl'>
          Fields marked with <span className='required'></span> are mandatory
        </label>
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {ServiceEditModalForm}
