import React, {useEffect, useMemo, useState, useCallback} from 'react'

import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {QUERIES, stringifyRequestQuery, toAbsoluteUrl} from '../../../_metronic/helpers'
import {convertFileToBase64} from '../../../utils/helperFn'
import SocialMediaDetails from './details/SocialMediaDetails'
import {createJob, getJobById, updateJob} from './core/_requests'
import {useQuery} from 'react-query'
import {useQueryRequest} from './core/QueryRequestProvider'
import {ToastContainer, toast} from 'react-toastify'
const validationSchema = Yup.object().shape({
  companyIntro: Yup.string().required('Company Intro is required'),

  youtube: Yup.string()
    // .required('Link is required')
    .matches(/^(ftp|http|https):\/\/[^ "]+$/, 'Invalid URL format. Please enter a valid URL.'),
  instagram: Yup.string()
    // .required('Link is required')
    .matches(/^(ftp|http|https):\/\/[^ "]+$/, 'Invalid URL format. Please enter a valid URL.'),
  linkedIn: Yup.string()
    // .required('Link is required')
    .matches(/^(ftp|http|https):\/\/[^ "]+$/, 'Invalid URL format. Please enter a valid URL.'),
  facebook: Yup.string()
    // .required('Link is required')
    .matches(/^(ftp|http|https):\/\/[^ "]+$/, 'Invalid URL format. Please enter a valid URL.'),
  twitter: Yup.string()
    // .required('Link is required')
    .matches(/^(ftp|http|https):\/\/[^ "]+$/, 'Invalid URL format. Please enter a valid URL.'),
})

const SocialMediaLinks: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  const [loading1, setLoading1] = useState(false)

  const {state} = useQueryRequest()
  const [query, setQuery] = useState(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.GEN_QNA_URL}-${query}`,
    () => {
      return getJobById(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const formik = useFormik({
    initialValues: {
      companyIntro: '',
      youtube: '',
      instagram: '',
      linkedIn: '',
      facebook: '',
      twitter: '',
      logo: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setLoading1(true)
      try {
        const jobId = response[0].id

        if (jobId) {
          await updateJob(jobId, values)
        } else {
          await createJob(values)
          toast.success('Slider created successfully!', {position: 'top-right'})
        }
        refetch()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
        setLoading1(false)
      }
    },
  })

  useEffect(() => {
    if (response && response.length > 0) {
      const firstElement = response[0]
      formik.setValues({
        companyIntro: firstElement.companyIntro || '',
        youtube: firstElement.youtube || '',
        instagram: firstElement.instagram || '',
        linkedIn: firstElement.linkedIn || '',
        facebook: firstElement.facebook || '',
        twitter: firstElement.twitter || '',
        logo: firstElement.logo || '',
      })
      setSelectedImage(firstElement.logo || null)
    }
  }, [response, formik.setValues])

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const file = e.target.files?.[0]
    if (file) {
      const image = new Image()
      const imageUrl = URL.createObjectURL(file)
      image.src = imageUrl
      image.onload = async () => {
        const {width, height} = image
        if (width !== 450 || height !== 80) {
          URL.revokeObjectURL(imageUrl)
          return toast.error('Logo Image must be 450x80 px ')
        }
        const base64 = await convertFileToBase64(file)
        if (type === 'logo') {
          formik.setFieldValue('logo', base64)
          setSelectedImage(imageUrl)
        }
      }
      image.onerror = () => {
        toast.error('Failed to load Image')
      }
    }
  }

  const handleImageRemove = (type: string) => {
    setSelectedImage(null)

    if (type === 'logo') {
      formik.setFieldValue('logo', '')
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Website Introduction</h3>
          </div>
        </div>
        <div className='card-body border-top p-9'>
          <form
            onSubmit={formik.handleSubmit}
            id='kt_signin_change_email'
            className='form'
            noValidate
          >
            <div className='row mb-6'>
              <div className='col-lg-12 '>
                <div className='fv-row mb-7 text-center'>
                  <label className='d-block fw-bold fs-6 mb-5'>Logo</label>

                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url('${blankImg}')`}}
                  >
                    <div
                      className=''
                      style={{
                        width: '100%',
                        height: '80px',
                        aspectRatio: '25/5',
                        backgroundImage: `url('${
                          (formik as any)?.logo ? (formik as any)?.logo : selectedImage
                        }')`,
                      }}
                    ></div>

                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      title='Change logo'
                    >
                      <i className='bi bi-pencil-fill fs-7'></i>

                      <input
                        type='file'
                        onChange={(e) => handleImageChange(e, 'logo')}
                        name='logo'
                        accept='.png, .jpg, .jpeg'
                      />
                      <input type='hidden' name='avatar_remove' />
                    </label>

                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel logo'
                      onClick={() => handleImageRemove('logo')}
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>

                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      title='Remove logo'
                      onClick={() => handleImageRemove('logo')}
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                  </div>

                  <div className='form-text'>
                    Allowed file types: png, jpg, jpeg. Dimension 450x80 px.
                  </div>
                </div>
              </div>
              <div className='col-lg-12 mb-4 '>
                <div className='fv-row mb-0'>
                  <label htmlFor='text' className='form-label fs-6 fw-bolder mb-3'>
                    Introduction Text
                  </label>

                  <textarea
                    placeholder='Enter Text'
                    className='form-control form-control-lg form-control-solid'
                    id='companyIntro'
                    name='companyIntro'
                    value={formik.values.companyIntro}
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.companyIntro && formik.errors.companyIntro && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.companyIntro}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-3 mb-4 mb-lg-0'>
                <div className='fv-row mb-0'>
                  <label htmlFor='youtube' className='form-label fs-6 fw-bolder mb-3'>
                    Youtube
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    id='youtube'
                    name='youtube'
                    placeholder='Enter Youtube url'
                    value={formik.values.youtube}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.youtube && formik.errors.youtube && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.youtube}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-3 mb-4 mb-lg-0'>
                <div className='fv-row mb-0'>
                  <label htmlFor='instagram' className='form-label fs-6 fw-bolder mb-3'>
                    Instagram
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    id='instagram'
                    name='instagram'
                    placeholder='Enter Instagram url'
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.instagram && formik.errors.instagram && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.instagram}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-3 mb-4 mb-lg-0'>
                <div className='fv-row mb-0'>
                  <label htmlFor='linkedIn' className='form-label fs-6 fw-bolder mb-3'>
                    Linkedin
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    id='linkedIn'
                    name='linkedIn'
                    placeholder='Enter Linkedin url'
                    value={formik.values.linkedIn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.linkedIn && formik.errors.linkedIn && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.linkedIn}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-3 mb-4 mb-lg-0'>
                <div className='fv-row mb-0'>
                  <label htmlFor='facebook' className='form-label fs-6 fw-bolder mb-3'>
                    Facebook
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    id='facebook'
                    name='facebook'
                    placeholder='Enter Facebook url'
                    value={formik.values.facebook}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.facebook && formik.errors.facebook && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.facebook}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-3 mb-4 mb-lg-0'>
                <div className='fv-row mb-0'>
                  <label htmlFor='facebook' className='form-label fs-6 fw-bolder mb-3'>
                    Twitter
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    id='twitter'
                    name='twitter'
                    placeholder='Enter Twitter url'
                    value={formik.values.twitter}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.twitter && formik.errors.twitter && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.twitter}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <button id='kt_signin_submit' type='submit' className='btn btn-primary  me-2 px-6'>
                {!loading1 && 'Update Link'}
                {loading1 && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <SocialMediaDetails />
      <ToastContainer />
    </>
  )
}

export {SocialMediaLinks}
