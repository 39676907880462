import {Button, Dropdown, Table} from 'react-bootstrap'
import React, {useRef} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {ToastContainer, toast} from 'react-toastify'
import {deleteFooter} from '../core/_requests'
import {KTIcon, QUERIES} from '../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'

const FooterInfomationDetails = ({responseData, onEdit, refetch}) => {
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const cardRef = useRef<HTMLDivElement>(null)

  const editPageHandler = (data) => {
    window.scrollTo(0, 0)
    onEdit(data)
  }

  const deleteItem = useMutation((id) => deleteFooter(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.FTR_QNA_URL}-${query}`])

      refetch()
    },
  })
  const handleDelete = async (id) => {
    await deleteItem.mutateAsync(id)
  }

  const socialMediaData = responseData
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Update Footer Links</h3>
          </div>
        </div>

        <div className='card-body p-5'>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Select List Type</th>
                <th>Footer Listing</th>
                <th>Footer Listing Url</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {socialMediaData
                ? socialMediaData.map((data, index) => (
                    <tr key={data.id}>
                      <td>{index + 1}</td>
                      <td>{data.pageCategory}</td>
                      <td>{data.pageName}</td>
                      <td>{data.pageLink}</td>

                      <td>
                        <div className='dropdown'>
                          <button
                            className='btn btn-secondary dropdown-toggle'
                            type='button'
                            id='dropdownMenuButton1'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            Action
                          </button>
                          <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                            <li>
                              <a className='dropdown-item' onClick={() => editPageHandler(data)}>
                                Edit
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' onClick={() => handleDelete(data.id)}>
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default FooterInfomationDetails
