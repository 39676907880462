// @ts-nocheck
import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { StatusCell } from './StatusCell'
import { ActionsCell } from './ActionsCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { News } from '../../core/_models'
import { DateCell } from './DateCell'
import { format, parseISO } from 'date-fns'

import { ApprovalStatusCell } from '../../../offers/table/columns/ApprovalStatusCell'

const servicesColumns: ReadonlyArray<Column<News>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'Topic',
    Cell: ({ ...props }) => <InfoCell service={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({ ...props }) => {
      const dateString: String = props.data[props.row.index].createdAt
      const parsedDate = parseISO(dateString)
      const formattedDate = format(parsedDate, 'MMM dd, yyyy')
      return <DateCell date={formattedDate} />
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <ActionsCell
        id={props.data[props.row.index].id}
        isDeleted={!!props.data[props.row.index]?.deletedAt}
      />
    ),
  },
]

export { servicesColumns }
