import React, {FC} from 'react'

const TermsCondition = ({termsAndConditions}) => {
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>Terms And Condition</h3>
      </div>
      <div className='card-body pt-3'>
        <div className='text-gray-800 mb-5'>{termsAndConditions}</div>
      </div>
    </div>
  )
}

export {TermsCondition}
