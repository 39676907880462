import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {NewsLatter, NewsLatterQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const NewsLatter_URL = `${API_URL}/news_letter`
const getNewsLatterCustomers = (query: string): Promise<NewsLatterQueryResponse> => {
  return axios.get(`${NewsLatter_URL}?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getNewsLatterById = (id: ID): Promise<NewsLatter | undefined> => {
  return (
    axios
      .get(`${NewsLatter_URL}/${id}`)
      .then(({data}: AxiosResponse) => {
        const {_id, __v, ...businessCategory} = data.data
        const modifiedUser: NewsLatter = {id: _id, ...businessCategory}
        return modifiedUser
      })
  )
}

const createNewsLatter = (offer: NewsLatter): Promise<NewsLatter | undefined> => {
  return axios
    .post(NewsLatter_URL, offer)
    .then((response: AxiosResponse<Response<NewsLatter>>) => response.data)
    .then((response: Response<NewsLatter>) => response.data)
}

const updateNewsLatter = (offer: NewsLatter): Promise<NewsLatter | undefined> => {
  return axios
    .put(`${NewsLatter_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<NewsLatter>>) => response.data)
    .then((response: Response<NewsLatter>) => response.data)
}

const deleteNewsLatter = (categoryId: ID): Promise<void> => {
  return axios.delete(`${NewsLatter_URL}/${categoryId}`).then(() => {})
}

const deleteSelectedEnquiries = (categoryIds: Array<ID>): Promise<void> => {
  const requests = categoryIds.map((id) => axios.delete(`${NewsLatter_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteNewsLatter,
  deleteSelectedEnquiries,
  updateNewsLatter,
  getNewsLatterById,
  createNewsLatter,
  getNewsLatterCustomers,
}
