import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {OfferType, OfferTypeQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const OFFER_URL = `${API_URL}/offerTypes`
const getOfferTypes = (query: string): Promise<OfferTypeQueryResponse> => {
  return axios.get(`${OFFER_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getOfferTypeById = (id: ID): Promise<OfferType | undefined> => {
  return axios.get(`${OFFER_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...user} = data.data
    const modifiedUser: OfferType = {id: _id, ...user}
    return modifiedUser
  })
}

const createOfferType = (offer: OfferType): Promise<OfferType | undefined> => {
  return axios
    .post(OFFER_URL, offer)
    .then((response: AxiosResponse<Response<OfferType>>) => response.data)
    .then((response: Response<OfferType>) => response.data)
}

const updateOfferType = (offer: OfferType): Promise<OfferType | undefined> => {
  return axios
    .put(`${OFFER_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<OfferType>>) => response.data)
    .then((response: Response<OfferType>) => response.data)
}

const deleteOfferType = (offerId: ID): Promise<void> => {
  return axios.delete(`${OFFER_URL}/${offerId}`).then(() => {})
}

const deleteSelectedOffersTypes = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.delete(`${OFFER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreOffersCategory = (offerIds: ID): Promise<void> => {
  return axios.get(`${OFFER_URL}/restore/${offerIds}`).then(() => {})
}
const restoreSelectedOffersCategories = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.get(`${OFFER_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}
export {
  deleteOfferType,
  restoreOffersCategory,
  restoreSelectedOffersCategories,
  deleteSelectedOffersTypes,
  updateOfferType,
  getOfferTypeById,
  createOfferType,
  getOfferTypes,
}
