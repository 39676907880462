import React, {FC} from 'react'

const Description = ({title = 'Description', description}) => {
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>{title}</h3>
      </div>
      <div className='card-body pt-3'>
        <div className='text-gray-800 mb-5'>
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export {Description}
