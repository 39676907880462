import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getOfferTypeById} from '../core/_requests'
import {OfferTypeEditModalForm} from './OfferTypeEditModalForm'
import {useQueryResponse} from '../core/QueryResponseProvider'

const OfferEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  // const {query} = useQueryResponse()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: offer,
    error,
  } = useQuery(
    `${QUERIES.OFFER_TYPES}-${itemIdForUpdate}`,
    () => {
      const offer = getOfferTypeById(itemIdForUpdate)
      return offer
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <OfferTypeEditModalForm isUserLoading={isLoading} offer={{id: undefined}} />
  }
  if (!isLoading && !error && offer) {
    return <OfferTypeEditModalForm isUserLoading={isLoading} offer={offer} />
  }

  return null
}

export {OfferEditModalFormWrapper}
