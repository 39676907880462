 
import {FC} from 'react'
import {BusinessComment} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
type Props = {
  blog: BusinessComment
}

const InfoCell: FC<Props> = ({blog}) => {
  return (
    <div className='d-flex align-items-center pointer'>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{blog?.comment}</a>
      </div>
    </div>
  )
}

export {InfoCell}
