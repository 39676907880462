// @ts-nocheck
import { Column } from 'react-table'
import { InfoCell } from './InfoCell'

import { StatusCell } from './StatusCell'
import { ActionsCell } from './ActionsCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { RealEstate } from '../../core/_models'
import { DateCell } from './DateCell'
import { format, parseISO } from 'date-fns'
import { Media } from './Media'
import { PlanDetails } from './PlanDetails'

const plansColumns: ReadonlyArray<Column<RealEstate>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'Name',
    Cell: ({ ...props }) => <InfoCell plan={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Images & Videos' className='min-w-125px' />
    ),
    id: 'media',
    Cell: ({ ...props }) => (
      <Media
        images={props.data[props.row.index].images}
        videos={props.data[props.row.index].videos}
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({ ...props }) => {
      const dateString: String = props.data[props.row.index].createdAt
      const parsedDate = parseISO(dateString)
      const formattedDate = format(parsedDate, 'MMM dd, yyyy')
      return <DateCell date={formattedDate} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Plan Details' className='min-w-125px' />
    ),
    id: 'plan',
    Cell: ({ ...props }) => <PlanDetails offers={props.data[props.row.index].offers} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <ActionsCell
        isDefault={props.data[props.row.index].isDefault}
        id={props.data[props.row.index].id}
        isDeleted={!!props.data[props.row.index]?.deletedAt}
      />
    ),
  },
]

export { plansColumns }
