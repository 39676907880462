import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {NewsCategory, NewsCategoryQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const BLOG_CATEGORY_URL = `${API_URL}/news_categories`
const getNewsCategories = (query: string): Promise<NewsCategoryQueryResponse> => {
  return axios.get(`${BLOG_CATEGORY_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getNewsCategoryById = (id: ID): Promise<NewsCategory | undefined> => {
  return axios.get(`${BLOG_CATEGORY_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...user} = data.data
    const modifiedUser: NewsCategory = {id: _id, ...user}
    return modifiedUser
  })
}

const createNewsCategory = (offer: NewsCategory): Promise<NewsCategory | undefined> => {
  return axios
    .post(BLOG_CATEGORY_URL, offer)
    .then((response: AxiosResponse<Response<NewsCategory>>) => response.data)
    .then((response: Response<NewsCategory>) => response.data)
}

const updateNewsCategory = (offer: NewsCategory): Promise<NewsCategory | undefined> => {
  return axios
    .put(`${BLOG_CATEGORY_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<NewsCategory>>) => response.data)
    .then((response: Response<NewsCategory>) => response.data)
}

const deleteNewsCategory = (offerId: ID): Promise<void> => {
  return axios.delete(`${BLOG_CATEGORY_URL}/${offerId}`).then(() => {})
}

const deleteSelectedOffersTypes = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.delete(`${BLOG_CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreSelectedNewsCategories = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.get(`${BLOG_CATEGORY_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreNews = (offerId: ID): Promise<void> => {
  return axios.get(`${BLOG_CATEGORY_URL}/restore/${offerId}`).then(() => {})
}
export {
  restoreSelectedNewsCategories,
  deleteNewsCategory,
  restoreNews,
  deleteSelectedOffersTypes,
  updateNewsCategory,
  getNewsCategoryById,
  createNewsCategory,
  getNewsCategories,
}
