import {useListView} from '../../core/ListViewProvider'
import {ListToolbar} from './ListToolbar'
import {ListGrouping} from './ListGrouping'
import {SearchComponent} from './SearchComponent'

const ListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SearchComponent /> 
      <div className='card-toolbar'> 
        {selected.length > 0 ? <ListGrouping /> : <ListToolbar />}
         
      </div> 
    </div>
  )
}

export {ListHeader}
