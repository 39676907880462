import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {BusinessComment, BusinessCommentQueryResponse} from './_models'
const API_URL = process.env.REACT_APP_API_URL
const BusinessComment_URL = `${API_URL}/businesses`
const getBusinessComments = (businessId: string, query: string): Promise<BusinessCommentQueryResponse> => {
  return axios 
    .get(`${BusinessComment_URL}/${businessId}/admin_reviews?${query}`)
    .then(({data}: AxiosResponse) => {
      return data
    })
}

const getBusinessCommentById = (businessId:ID,id: ID): Promise<BusinessComment | undefined> => {
  return axios.get(`${BusinessComment_URL}/${businessId}/reviews/${id}`).then(({data}: AxiosResponse) => {
    return data.data
  })
}

const createBusinessComment = (businessId: ID,businessComment: BusinessComment): Promise<BusinessComment | undefined> => {
  return axios
    .post(`${BusinessComment_URL}/${businessId}/reviews`, businessComment)
    .then((response: AxiosResponse<Response<BusinessComment>>) => response.data)
    .then((response: Response<BusinessComment>) => response.data)
}

const updateBusinessComment = (businessId:ID,businessComment: BusinessComment): Promise<BusinessComment | undefined> => {
  return axios
    .put(`${BusinessComment_URL}/${businessId}/reviews/${businessComment.id}`, businessComment)
    .then((response: AxiosResponse<Response<BusinessComment>>) => response.data)
    .then((response: Response<BusinessComment>) => response.data)
}

const deleteBusinessComment = (businessId:ID,businessCommentId: ID): Promise<void> => {
  return axios.delete(`${BusinessComment_URL}/${businessId}/reviews/${businessCommentId}`).then(() => {})
}

const deleteSelectedBusinessComments = (businessId:ID,businessCommentIds: Array<ID>): Promise<void> => {
  const requests = businessCommentIds.map((id) => axios.delete(`${BusinessComment_URL}/${businessId}/reviews/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteBusinessComment,
  deleteSelectedBusinessComments,
  updateBusinessComment,
  getBusinessCommentById,
  createBusinessComment,
  getBusinessComments,
}
