import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'

import {useParams, useSearchParams} from 'react-router-dom'

import {Loading} from '../../loading/Loading'
import {Business} from '../../../../apps/business-management/core/_models'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {updateBusinessRating} from '../../../../apps/business-management/core/_requests'
import {useRatingModal} from './RatingContext'
type Props = {
  isLoading: boolean
  business: Business
}

const editBlog = Yup.object().shape({
  averageRating: Yup.number().required('Average Rating is required'),
})

const RatingEditModalForm: FC<Props> = ({business, isLoading}) => {
  const [params, _] = useSearchParams()
  const {modalStatus, toggleModal} = useRatingModal()
  const businessId = params.get('Id') ? params.get('Id') : ''
  // const {setItemIdForUpdate} = useListView()
  const [blogForEdit] = useState<Business>({
    ...business,
    category: businessId,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      // refetch()
    }
    // setItemIdForUpdate(undefined)
    // window.location.reload()
    toggleModal(false)
  }

  const formik = useFormik({
    initialValues: blogForEdit,
    validationSchema: editBlog,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const averageRating: string | number = values.averageRating
        ? parseFloat(values.averageRating.toString())
        : 0
      const businessData = {
        ...values,
        averageRating: averageRating,
      }
      try {
        if (isNotEmpty(values.id)) {
          await updateBusinessRating(businessData)
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row'>
            <div className='col-lg-12'>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Average Rating</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Average Rating'
                  {...formik.getFieldProps('averageRating')}
                  type='number'
                  name='averageRating'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.averageRating && formik.errors.averageRating},
                    {
                      'is-valid': formik.touched.averageRating && !formik.errors.averageRating,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.averageRating && formik.errors.averageRating && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.averageRating}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {RatingEditModalForm}
