// import {ID, Response} from '../../../../_metronic/helpers'
import {ID, Response} from '../../../../_metronic/helpers'

export type Service = {
  id?: ID
  name?: string
  description?: string
  logo?:string,
  createdAt?: string
}

export type ServiceQueryResponse = Response<Array<Service>>

export const initialService:Service = {
  name: "",
  description: "",
  logo:"",
}

