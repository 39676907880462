import {KTCard} from '../../../_metronic/helpers'
import {ListHeader} from './components/header/ListHeader'

import {CategoryTable} from './CategoryTable'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {OfferEditModal} from './business-category-add/OfferEditModal'
import {ListViewProvider, useListView} from './core/ListViewProvider'

const BusinessCategoryList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <CategoryTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <OfferEditModal />}
    </>
  )
}

const BusinessCategoryListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BusinessCategoryList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BusinessCategoryListWrapper}
