import React, {useState, useContext} from 'react'

interface RatingModalContextValue {
  modalStatus: boolean
  toggleModal: (val: boolean) => void
}
export const initialValue: RatingModalContextValue = {
  toggleModal: () => {},
  modalStatus: false,
}

const RatingModalContext = React.createContext<RatingModalContextValue>(initialValue)

export const RatingModalProvider = ({children}: {children: React.ReactNode}) => {
  const [modalStatus, setModalStatus] = useState<boolean>(false)

  const toggleModal = (val: boolean) => {
    setModalStatus(val)
  }

  return (
    <RatingModalContext.Provider value={{modalStatus, toggleModal}}>
      {children}
    </RatingModalContext.Provider>
  )
}

export const useRatingModal = () => useContext(RatingModalContext)
