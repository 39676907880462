import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {FaqQna, FaqQnaQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const FAQ_QNA_URL = `${API_URL}/faqs`
const getFaqQna = (query: string): Promise<FaqQnaQueryResponse> => {
  return axios.get(`${FAQ_QNA_URL}?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getFaqQnaById = (id: ID): Promise<FaqQna | undefined> => {
  return axios.get(`${FAQ_QNA_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...user} = data.data
    const modifiedUser: FaqQna = {id: _id, ...user}
    return modifiedUser
  })
}
const createFaqQna = (offer: FaqQna): Promise<FaqQna | undefined> => {
  return axios
    .post(FAQ_QNA_URL, offer)
    .then((response: AxiosResponse<Response<FaqQna>>) => response.data)
    .then((response: Response<FaqQna>) => response.data)
}

const updateFaqQnaCategory = (offer: FaqQna): Promise<FaqQna | undefined> => {
  return axios
    .put(`${FAQ_QNA_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<FaqQna>>) => response.data)
    .then((response: Response<FaqQna>) => response.data)
}

const deleteFaqQnaCategory = (offerId: ID): Promise<void> => {
  return axios.delete(`${FAQ_QNA_URL}/${offerId}`).then(() => {})
}

const deleteSelectedQnaFaqTypes = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.delete(`${FAQ_QNA_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteFaqQnaCategory,
  deleteSelectedQnaFaqTypes,
  updateFaqQnaCategory,
  getFaqQnaById,
  createFaqQna,
  getFaqQna,
}
